
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';

import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import UsuarioModal from '../../Cadastros/UsuarioModal/UsuarioModal';
import { NumericFormat } from 'react-number-format';
import { maskNumberBr } from '../../../Auxiliar/Masks';
import ReactTooltip from 'react-tooltip';
import { IoIosInformationCircleOutline } from 'react-icons/io';

class CriarRegistro extends Component {



  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/registro_gestao_interna',
      loading_screen: false,
      PontosColetas: [],
      Residuos: [],
      Rejeitos: [],
      ResiduosPonto: [],
      RejeitosPonto: [],
      ResiduosEstoque: [],
      RejeitosEstoque: [],
      Estoques: [],
      Responsaveis: [],
      Responsaveis_all: [],

      Rejeito_selecionado: {},
      Residuo_selecionado: {},
      MaterialSelecionado: {},

      OrigemMaterial: 'Interna',
      Peso_kg: '',
      Volume_lt: '',
      Peso: '',
      Volume: '',
      EstoqueArmazenamento: '',
      atraso: 'não',
      MaterialAgrupado: '',
      Responsavel: '',
      Rastreamento: '',
      DadosRastreamento: '',
      TipoMaterial: 'Resíduo',
      ChaveNota: '',
      NumeroNota: '',
      SerieNota: '',
      PontoColeta: '',
      ResiduoRejeito: '',
      DataAtual: '',
      DataEntrada: '',
      Descricao: '',
      Quantidade: '',
      UnidadeQuantidade: 'un',
      UnidadeQuantidadeId: '',
      UnidadeEquivalenciaId: '',
      Equivalencia: '',
      ValorEquivalente: '',
      UnidadeEquivalencia: 'un',
      loading_save: false,
      msgErro: '',
      estoque_disabled: true,
      PrincipalMaxAmount: '',
      PrincipalMaxAmount: '',
      PrincipalUnity: '',
      SecondaryUnity: ''
    };
  }



  // busca dados para preenchimento dos <selects>---------------------------------------
  get_data() {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    // busca dados da tabela pessoa para <select> contato---------------------------------------
    fetch(`${URL}api/get_dados_registro_gestao`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.error(erro);
          this.setState({ redirect: true });
        }
        else {
          let estoque = resp.estoque.filter(item => item.id === this.state.EstoqueArmazenamento)[0]

          this.setState({
            loading_screen: false,
            PontosColetas: resp.ponto_coleta,
            Responsaveis: estoque ? estoque.pessoas : [],
            TipoMateriais: resp.tipo_material,
            Responsaveis_all: resp.responsavel,
            Estoques: resp.estoque
          });
        }
      } catch (err) {
        this.setState({ redirect: true });
        console.error(err);
      }
    })
      .catch((err) => {
        this.setState({ redirect: true });
        console.error(err);
      });


  }



  // insere dados no banco  botão salvar-----------------------------
  salvar() {

    let form = new FormData();
    form.append('origem_material', this.state.OrigemMaterial);
    form.append('estoque_armazenamento_id', this.state.EstoqueArmazenamento);
    // form.append('material_agrupado', this.state.MaterialAgrupado);
    // form.append('rastreamento', this.state.Rastreamento);
    // form.append('dados_rastreamento', this.state.DadosRastreamento);
    form.append('tipo_material', this.state.TipoMaterial);
    // form.append('chave_nfe', this.state.ChaveNota);
    // form.append('numero_nfe', this.state.NumeroNota);
    // form.append('serie_nfe', this.state.SerieNota);
    form.append('pontos_coleta_id', this.state.PontoColeta);
    form.append('residuo_rejeito_id', this.state.ResiduoRejeito);
    form.append('data_entrada', this.state.DataEntrada);
    form.append('descricao', this.state.Descricao);
    form.append('quantidade', this.state.Quantidade);
    form.append('unidade_quantidade', this.state.UnidadeQuantidade);
    form.append('unidade_quantidade_id', this.state.UnidadeQuantidadeId);

    form.append('equivalencia', this.state.ValorEquivalente);
    form.append('unidade_equivalencia', this.state.UnidadeEquivalencia);
    form.append('unidade_equivalencia_id', this.state.UnidadeEquivalenciaId);

    form.append('data_em_atraso', this.state.atraso);
    form.append('responsavel_id', this.state.Responsavel);
    form.append('peso_kg', this.state.Peso_kg);
    form.append('volume_lt', this.state.Volume_lt);
    form.append('peso', this.state.Peso);
    form.append('volume', this.state.Volume);

    this.setState({ loading_save: true, msgErro: '' });

    fetch(`${URL}api/store_registro_gestao`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      },
      body: form
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.error(erro);
          // document.getElementsByClassName('app')[0].scrollTo({ top: 0, behavior: 'smooth' });
          window.scrollTo(500, 0);
          this.setState({ loading_save: false, msgErro: erro });
        }
        else {
          this.setState({ loading_save: false, redirect: true });
        }
      } catch (err) {
        console.error(err);
        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
      }
    })
      .catch((err) => {
        console.error(err);
        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
      });
  }

  // carrega dados para preenchimento dos <select> apos a montagem do componente
  componentDidMount() {
    let data = new Date();
    let dia = String(data.getDate()).padStart(2, '0');
    let mes = String(data.getMonth() + 1).padStart(2, '0');
    let ano = data.getFullYear();
    this.setState({
      DataAtual: ano + '-' + mes + '-' + dia,
      DataEntrada: ano + '-' + mes + '-' + dia
    })
    this.get_data();
  }

  componentDidUpdate() {
    if (this.state.EstoqueArmazenamento) {
      let estoque_selecionado = { ...this.state.Estoques.filter(p => p.id == this.state.EstoqueArmazenamento) };
    }
  }

  getTooltipData = (estoqueSelecionado, residuoSelecionado) => {
    let PrincipalMaxAmount = ''
    let SecondaryMaxAmount = ''

    if (residuoSelecionado.unidade_principal.tipo === 'Peso') {
      PrincipalMaxAmount = estoqueSelecionado.capacidade_maxima_peso / residuoSelecionado.unidade_principal.equivalencia
      SecondaryMaxAmount = estoqueSelecionado.capacidade_maxima_volume / residuoSelecionado.unidade_secundaria.equivalencia
    } else {
      PrincipalMaxAmount = estoqueSelecionado.capacidade_maxima_volume / residuoSelecionado.unidade_principal.equivalencia
      SecondaryMaxAmount = estoqueSelecionado.capacidade_maxima_peso / residuoSelecionado.unidade_secundaria.equivalencia
    }

    return { PrincipalMaxAmount, SecondaryMaxAmount }
  }


  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}

        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">


              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Registar entrada </h4>
              <p style={{ fontSize: '1rem', color: 'black' }}>Registre as entradas de materiais</p>
              <br />

              {/* <hr /> */}
              {this.state.loading_screen == false &&
                <div>{this.state.msgErro != '' && <div>
                  <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                  </div>
                  <br />
                </div>}

                  <div className="row">
                    <div className="col-12">

                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Origem do material<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <select value={this.state.OrigemMaterial} className="form-control"
                            onChange={(e) => {
                              this.setState({
                                OrigemMaterial: e.target.value,
                                EstoqueArmazenamento: '',
                                Residuos: [],
                                Rejeitos: [],
                                ResiduosEstoque: [],
                                RejeitosEstoque: [],
                                ResiduosPonto: [],
                                RejeitosPonto: [],
                                ResiduoRejeito: '',
                                PontoColeta: '',
                                ValorEquivalente: '',

                                Quantidade: '',
                                Peso_kg: '',
                                Volume_lt: '',
                                Peso: '',
                                Volume: '',

                                Equivalencia: '',
                                UnidadeQuantidade: 'un',
                                UnidadeEquivalencia: 'un',
                                UnidadeQuantidadeId: '',
                                UnidadeEquivalenciaId: '',
                                MaterialSelecionado: {},
                                Responsaveis: [],
                                Responsavel: ''
                              })
                            }}>
                            <option value={'Interna'}>Interna</option>
                            <option value={'Externa'}>Externa</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Estoque de armazenamento<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <select value={this.state.EstoqueArmazenamento} className="form-control"
                            onChange={(e) => {
                              if (this.state.OrigemMaterial == 'Interna') {
                                if (e.target.value == '') {
                                  this.setState({
                                    EstoqueArmazenamento: '',
                                    PontoColeta: '',
                                    Residuos: [],
                                    Rejeitos: [],
                                    ResiduoRejeito: '',
                                    Quantidade: '',
                                    Peso_kg: '',
                                    Volume_lt: '',
                                    Peso: '',
                                    Volume: '',
                                    ValorEquivalente: '',
                                    Equivalencia: '',
                                    UnidadeQuantidade: 'un',
                                    UnidadeEquivalencia: 'un',
                                    UnidadeQuantidadeId: '',
                                    UnidadeEquivalenciaId: '',
                                    Responsaveis: [],
                                    Responsavel: '',
                                    MaterialSelecionado: {},
                                    estoque_disabled: false,
                                  });
                                } else {
                                  let estoque_selecionado = { ...this.state.Estoques.filter(p => p.id == e.target.value) };
                                  let autorizados = estoque_selecionado[0].pessoas;
                                  let responsaveis = [];

                                  for (let i = 0; i < autorizados.length; i++) {
                                    for (let j = 0; j < this.state.Responsaveis_all.length; j++) {
                                      if (autorizados[i].user_id == this.state.Responsaveis_all[j].id) {
                                        responsaveis.push(this.state.Responsaveis_all[j]);
                                        j = this.state.Responsaveis_all.length
                                        break;
                                      }
                                    }
                                  }
                                  this.setState({
                                    Residuos: estoque_selecionado[0].residuos,
                                    Rejeitos: estoque_selecionado[0].rejeitos,
                                    EstoqueArmazenamento: e.target.value,
                                    ResiduoRejeito: '',
                                    ValorEquivalente: '',
                                    Equivalencia: '',
                                    Quantidade: '',
                                    Peso_kg: '',
                                    Volume_lt: '',
                                    Peso: '',
                                    Volume: '',
                                    UnidadeQuantidade: 'un',
                                    UnidadeEquivalencia: 'un',
                                    UnidadeQuantidadeId: '',
                                    UnidadeEquivalenciaId: '',
                                    MaterialSelecionado: {},
                                    Responsaveis: responsaveis,
                                    Responsavel: '',
                                    estoque_disabled: false,
                                  });
                                }
                              }

                              // EXTERNA
                              else {
                                if (e.target.value == '') {
                                  this.setState({
                                    EstoqueArmazenamento: '',
                                    PontoColeta: '',
                                    ResiduosEstoque: [],
                                    RejeitosEstoque: [],
                                    Residuos: [],
                                    Rejeitos: [],
                                    ResiduoRejeito: '',
                                    Quantidade: '',
                                    Peso_kg: '',
                                    Volume_lt: '',
                                    Peso: '',
                                    Volume: '',
                                    ValorEquivalente: '',
                                    Equivalencia: '',
                                    UnidadeQuantidade: 'un',
                                    UnidadeEquivalencia: 'un',
                                    UnidadeQuantidadeId: '',
                                    UnidadeEquivalenciaId: '',
                                    MaterialSelecionado: {},
                                    Responsaveis: [],
                                    Responsavel: '',
                                    estoque_disabled: true,
                                  });
                                } else {
                                  let estoque_selecionado = { ...this.state.Estoques.filter(p => p.id == e.target.value) };
                                  let autorizados = estoque_selecionado[0].pessoas;
                                  let responsaveis = [];
                                  for (let i = 0; i < autorizados.length; i++) {
                                    for (let j = 0; j < this.state.Responsaveis_all.length; j++) {
                                      if (autorizados[i].user_id == this.state.Responsaveis_all[j].id) {
                                        responsaveis.push(this.state.Responsaveis_all[j]);
                                        j = this.state.Responsaveis_all.length
                                        break;
                                      }
                                    }
                                  }


                                  let residuos = [];
                                  let rejeitos = [];
                                  for (let i = 0; i < this.state.ResiduosPonto.length; i++) {
                                    for (let j = 0; j < estoque_selecionado[0].residuos.length; j++) {
                                      if (this.state.ResiduosPonto[i].id == estoque_selecionado[0].residuos[j].id) {
                                        residuos.push(this.state.ResiduosPonto[i])

                                      }
                                    }
                                  }
                                  for (let i = 0; i < this.state.RejeitosPonto.length; i++) {
                                    for (let j = 0; j < estoque_selecionado[0].rejeitos.length; j++) {
                                      if (this.state.RejeitosPonto[i].id == estoque_selecionado[0].rejeitos[j].id) {
                                        rejeitos.push(this.state.RejeitosPonto[i])
                                      }
                                    }
                                  }

                                  this.setState({
                                    Residuos: residuos,
                                    Rejeitos: rejeitos,
                                    EstoqueArmazenamento: e.target.value,
                                    ResiduosEstoque: estoque_selecionado[0].residuos,
                                    RejeitosEstoque: estoque_selecionado[0].rejeitos,
                                    ResiduoRejeito: '',
                                    ValorEquivalente: '',
                                    Equivalencia: '',
                                    Quantidade: '',
                                    Peso_kg: '',
                                    Volume_lt: '',
                                    Peso: '',
                                    Volume: '',
                                    UnidadeQuantidade: 'un',
                                    UnidadeEquivalencia: 'un',
                                    UnidadeQuantidadeId: '',
                                    UnidadeEquivalenciaId: '',
                                    MaterialSelecionado: {},
                                    Responsaveis: responsaveis,
                                    Responsavel: '',
                                    estoque_disabled: false

                                  });
                                }
                              }
                            }}>
                            <option value={''}>Selecione uma opção</option>
                            {this.state.Estoques.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}
                          </select>
                        </div>
                      </div>
                      {this.state.OrigemMaterial == 'Externa' && <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Ponto de coleta<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <select value={this.state.PontoColeta} className="form-control"
                            onChange={(e) => {
                              if (e.target.value == '') {
                                this.setState({
                                  PontoColeta: '',
                                  ResiduosPonto: [],
                                  RejeitosPonto: [],
                                  Residuos: [],
                                  Rejeitos: [],
                                  ResiduoRejeito: '',
                                  Quantidade: '',
                                  Peso_kg: '',
                                  Volume_lt: '',
                                  Peso: '',
                                  Volume: '',
                                  ResiduoRejeito: '',
                                  ValorEquivalente: '',
                                  Equivalencia: '',
                                  UnidadeQuantidade: 'un',
                                  UnidadeEquivalencia: 'un',
                                  UnidadeQuantidadeId: '',
                                  UnidadeEquivalenciaId: '',
                                  MaterialSelecionado: {},
                                  Responsaveis: [],
                                  Responsavel: '',
                                });
                              } else {
                                let residuos = [];
                                let rejeitos = [];
                                let ponto_selecionado = { ...this.state.PontosColetas.filter(p => p.id == e.target.value) };
                                let resposaveis_geral = {};
                                let responsaveis = [];
                                if (this.state.EstoqueArmazenamento != '') {
                                  let estoque_selecionado = { ...this.state.Estoques.filter(p => p.id == this.state.EstoqueArmazenamento) };
                                  let autorizados = [...ponto_selecionado[0].pessoas];
                                  autorizados.push(...estoque_selecionado[0].pessoas);

                                  for (let i = 0; i < autorizados.length; i++) {
                                    for (let j = 0; j < this.state.Responsaveis_all.length; j++) {
                                      if ((autorizados[i].user_id == this.state.Responsaveis_all[j].id
                                      ) && resposaveis_geral[autorizados[i].user_id] == undefined) {
                                        responsaveis.push(this.state.Responsaveis_all[j]);
                                        resposaveis_geral[autorizados[i].user_id] = autorizados[i].user_id;
                                        j = this.state.Responsaveis_all.length
                                        break;
                                      }
                                    }
                                  }
                                }


                                for (let i = 0; i < this.state.ResiduosEstoque.length; i++) {
                                  for (let j = 0; j < ponto_selecionado[0].residuos.length; j++) {
                                    if (this.state.ResiduosEstoque[i].id == ponto_selecionado[0].residuos[j].id) {
                                      residuos.push(this.state.ResiduosEstoque[i])
                                    }
                                  }
                                }
                                for (let i = 0; i < this.state.RejeitosEstoque.length; i++) {
                                  for (let j = 0; j < ponto_selecionado[0].rejeitos.length; j++) {
                                    if (this.state.RejeitosEstoque[i].id == ponto_selecionado[0].rejeitos[j].id) {
                                      rejeitos.push(this.state.RejeitosEstoque[i])
                                    }
                                  }
                                }
                                this.setState({
                                  Residuos: residuos,
                                  Rejeitos: rejeitos,
                                  PontoColeta: e.target.value,
                                  ResiduosPonto: ponto_selecionado[0].residuos,
                                  RejeitosPonto: ponto_selecionado[0].rejeitos,
                                  ResiduoRejeito: '',
                                  ValorEquivalente: '',
                                  Equivalencia: '',
                                  Quantidade: '',
                                  Peso_kg: '',
                                  Volume_lt: '',
                                  Peso: '',
                                  Volume: '',
                                  UnidadeQuantidade: 'un',
                                  UnidadeEquivalencia: 'un',
                                  UnidadeQuantidadeId: '',
                                  UnidadeEquivalenciaId: '',
                                  MaterialSelecionado: {},
                                  Responsaveis: responsaveis,
                                  Responsavel: '',
                                });
                              }
                            }}>
                            <option value={''}>Selecione uma opção</option>
                            {this.state.PontosColetas.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}
                          </select>
                        </div>
                      </div>}
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Tipo de material<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <select value={this.state.TipoMaterial} className="form-control"
                            onChange={(e) => {
                              this.setState({
                                ResiduoRejeito: '',
                                ValorEquivalente: '',
                                Equivalencia: '',
                                Quantidade: '',
                                Peso_kg: '',
                                Volume_lt: '',
                                Peso: '',
                                Volume: '',
                                UnidadeQuantidade: 'un',
                                UnidadeEquivalencia: 'un',
                                UnidadeQuantidadeId: '',
                                UnidadeEquivalenciaId: '',
                                TipoMaterial: e.target.value,
                                MaterialSelecionado: {},

                              })
                            }}>
                            <option value={'Resíduo'}>Resíduo</option>
                            <option value={'Rejeito'}>Rejeito</option>
                          </select>
                        </div>
                      </div>
                      {this.state.TipoMaterial == 'Resíduo' &&
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Resíduo<b style={{ color: 'red' }}>*</b></label>
                          <div className="col-sm-12">
                            <select value={this.state.ResiduoRejeito} className="form-control"
                              onChange={(e) => {
                                if (e.target.value == '') {
                                  this.setState({
                                    Quantidade: '',
                                    Peso_kg: '',
                                    Volume_lt: '',
                                    Peso: '',
                                    Volume: '',
                                    ResiduoRejeito: '',
                                    ValorEquivalente: '',
                                    Equivalencia: '',
                                    UnidadeQuantidade: 'un',
                                    UnidadeEquivalencia: 'un',
                                    UnidadeQuantidadeId: '',
                                    UnidadeEquivalenciaId: '',
                                    MaterialSelecionado: {},

                                  });
                                } else {
                                  let residuo_id = e.target.value;
                                  let residuo_selecionado = { ...this.state.Residuos.filter(residuo => residuo.id == residuo_id) };
                                  let estoqueSelecionado = this.state.Estoques.find(item => item.id === this.state.EstoqueArmazenamento)

                                  const { PrincipalMaxAmount, SecondaryMaxAmount } = this.getTooltipData(estoqueSelecionado, residuo_selecionado[0])

                                  this.setState({
                                    Quantidade: '',
                                    Peso_kg: '',
                                    Volume_lt: '',
                                    Peso: '',
                                    Volume: '',
                                    ValorEquivalente: '',
                                    ResiduoRejeito: e.target.value,
                                    Equivalencia: residuo_selecionado[0].equivalencia,
                                    MaterialSelecionado: residuo_selecionado[0],
                                    UnidadeQuantidade: residuo_selecionado[0].unidade_principal.nome,
                                    UnidadeEquivalencia: residuo_selecionado[0].unidade_secundaria.nome,
                                    UnidadeQuantidadeId: residuo_selecionado[0].unidade_principal.id,
                                    UnidadeEquivalenciaId: residuo_selecionado[0].unidade_secundaria.id,
                                    PrincipalUnity: residuo_selecionado[0].unidade_principal.sigla,
                                    SecondaryUnity: residuo_selecionado[0].unidade_secundaria.sigla,
                                    PrincipalMaxAmount,
                                    SecondaryMaxAmount,
                                  });
                                }
                              }}>
                              <option value={''}>Selecione uma opção</option>
                              {this.state.Residuos.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                            </select>
                          </div>
                        </div>}
                      {this.state.TipoMaterial == 'Rejeito' &&
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Rejeito<b style={{ color: 'red' }}>*</b></label>
                          <div className="col-sm-12">
                            <select value={this.state.ResiduoRejeito} className="form-control"
                              onChange={(e) => {
                                if (e.target.value == '') {
                                  this.setState({
                                    Quantidade: '',
                                    Peso_kg: '',
                                    Volume_lt: '',
                                    Peso: '',
                                    Volume: '',
                                    ResiduoRejeito: '',
                                    ValorEquivalente: '',
                                    Equivalencia: '',
                                    UnidadeQuantidade: 'un',
                                    UnidadeEquivalencia: 'un',
                                    UnidadeQuantidadeId: '',
                                    UnidadeEquivalenciaId: '',
                                    MaterialSelecionado: {},

                                  });
                                } else {
                                  let rejeito_id = e.target.value;
                                  let rejeito_selecionado = { ...this.state.Rejeitos.filter(rejeito => rejeito.id == rejeito_id) };
                                  let estoqueSelecionado = this.state.Estoques.find(item => item.id === this.state.EstoqueArmazenamento)

                                  const { PrincipalMaxAmount, SecondaryMaxAmount } = this.getTooltipData(estoqueSelecionado, rejeito_selecionado[0])

                                  this.setState({
                                    Quantidade: '',
                                    Peso_kg: '',
                                    Volume_lt: '',
                                    Peso: '',
                                    Volume: '',
                                    ValorEquivalente: '',
                                    ResiduoRejeito: e.target.value,
                                    Equivalencia: rejeito_selecionado[0].equivalencia,
                                    MaterialSelecionado: rejeito_selecionado[0],
                                    UnidadeQuantidade: rejeito_selecionado[0].unidade_principal.nome,
                                    UnidadeEquivalencia: rejeito_selecionado[0].unidade_secundaria.nome,
                                    UnidadeQuantidadeId: rejeito_selecionado[0].unidade_principal.id,
                                    UnidadeEquivalenciaId: rejeito_selecionado[0].unidade_secundaria.id,
                                    PrincipalUnity: rejeito_selecionado[0].unidade_principal.sigla,
                                    SecondaryUnity: rejeito_selecionado[0].unidade_secundaria.sigla,
                                    PrincipalMaxAmount,
                                    SecondaryMaxAmount,

                                  });
                                }
                              }}>
                              <option value={''}>Selecione uma opção</option>
                              {this.state.Rejeitos.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                            </select>
                          </div>
                        </div>}
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Data de entrada <b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <input className="form-control" value={this.state.DataEntrada} type="date" style={{ maxWidth: 168 }} max={this.state.DataAtual} onChange={(e) => {
                            if (e.target.value > this.state.DataAtual) {
                              e.target.value = this.state.DataAtual
                            }
                            e.target.value < this.state.DataAtual ? this.setState({ atraso: 'sim' }) : this.setState({ atraso: 'não' });
                            this.setState({ DataEntrada: e.target.value });
                          }} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Descrição </label>
                        <div className="col-sm-12">
                          <textarea className="form-control" placeholder="Descrição" value={this.state.Descricao} type="text" onChange={(e) => { this.setState({ Descricao: e.target.value }) }} />
                        </div>
                      </div>
                      <div className="form-group row">

                      </div>
                      <div className="form-group  row">
                        <div className=" col-sm-12">
                          {this.state.MaterialSelecionado.id != undefined &&
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <label className="col-sm-12 col-form-label">Quantidade <b style={{ color: 'red' }}>*</b>
                                  <IoIosInformationCircleOutline data-tip={`Capacidade Máxima: ${maskNumberBr(this.state.PrincipalMaxAmount + '')} ${this.state.PrincipalUnity} - ${maskNumberBr(this.state.SecondaryMaxAmount + '')} ${this.state.SecondaryUnity}`} />
                                  <ReactTooltip place="bottom" effect="float" />
                                </label>

                                <div className="input-group mb-3">
                                  <NumericFormat type='text' className="form-control"
                                    valueIsNumericString
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator="."
                                    decimalScale={2}
                                    decimalSeparator=","
                                    placeholder={"Quantidade em " + (this.state.MaterialSelecionado.unidade_principal.tipo !== 'Peso' ? this.state.MaterialSelecionado.unidade_principal.tipo.toLowerCase() : 'peso')}
                                    onValueChange={(values, e) => {
                                      const { EstoqueArmazenamento, MaterialSelecionado, Estoques, Equivalencia } = this.state;
                                      const value = values.value

                                      let message = '';
                                      let pesoKg;
                                      let volumeLt;
                                      let peso_un;
                                      let volume_un;
                                      let estoqueSelecionado = Estoques.find(item => item.id === EstoqueArmazenamento);
                                      let equivalencia = MaterialSelecionado.unidade_principal.equivalencia;

                                      const pesoMaximo = parseFloat(estoqueSelecionado.capacidade_maxima_peso) * parseFloat(estoqueSelecionado.unidade_peso.equivalencia);
                                      const volumeMaximo = parseFloat(estoqueSelecionado.capacidade_maxima_volume) * parseFloat(estoqueSelecionado.unidade_volume.equivalencia);
                                      // ${estoqueSelecionado.unidade_volume.sigla}
                                      // ${estoqueSelecionado.unidade_peso.sigla}
                                      if (MaterialSelecionado.unidade_principal.tipo === 'Peso') {
                                        pesoKg = value * MaterialSelecionado.unidade_principal.equivalencia;
                                        volumeLt = (value * Equivalencia) * MaterialSelecionado.unidade_secundaria.equivalencia;
                                        peso_un = value;
                                        volume_un = (value * Equivalencia);

                                        if ((parseFloat(value) * parseFloat(MaterialSelecionado.equivalencia)) * MaterialSelecionado.unidade_secundaria.equivalencia > volumeMaximo) {
                                          message = `Total de volume maior que capacidade do estoque (${maskNumberBr(volumeMaximo)} L)`;
                                        }

                                        if (value * equivalencia > pesoMaximo) {
                                          message = `Total de peso maior que capacidade do estoque (${maskNumberBr(pesoMaximo)} Kg)`;
                                        }
                                      }

                                      if (MaterialSelecionado.unidade_principal.tipo !== 'Peso') {
                                        pesoKg = (value * Equivalencia) * MaterialSelecionado.unidade_secundaria.equivalencia;
                                        volumeLt = value * MaterialSelecionado.unidade_principal.equivalencia;
                                        volume_un = value;
                                        peso_un = (value * Equivalencia);

                                        if ((parseFloat(value) * parseFloat(MaterialSelecionado.equivalencia)) * MaterialSelecionado.unidade_secundaria.equivalencia > pesoMaximo) {
                                          message = `Total de peso maior que capacidade do estoque (${maskNumberBr(pesoMaximo)} Kg)`;
                                        }

                                        if (value * equivalencia > volumeMaximo) {
                                          message = `Total de volume maior que capacidade do estoque (${maskNumberBr(volumeMaximo)} L)`;
                                        }
                                      }

                                      this.setState({
                                        Peso_kg: pesoKg,
                                        Volume_lt: volumeLt,
                                        Peso: peso_un,
                                        Volume: volume_un,
                                        QuantityError: message || false,
                                        Quantidade: value,
                                        ValorEquivalente: value * Equivalencia,
                                      });
                                    }}
                                    aria-describedby="basic-addon3"
                                    value={this.state.Quantidade} />
                                  <span className="input-group-text" id="basic-addon3" style={{ fontWeight: 'bold' }}>{this.state.UnidadeQuantidade}</span>
                                  {this.state.QuantityError && <b style={{ color: 'red', fontSize: '12px' }}>{this.state.QuantityError}</b>}
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <label className="col-sm-12 col-form-label">Equivalência <b style={{ color: 'red' }}>*</b></label>
                                <div className="input-group mb-3">
                                  <NumericFormat type='text' className="form-control"
                                    valueIsNumericString
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator="."
                                    decimalScale={2}
                                    decimalSeparator=","
                                    disabled
                                    value={this.state.ValorEquivalente} aria-describedby="basic-addon3" />
                                  <span className="input-group-text" id="basic-addon3" style={{ fontWeight: 'bold' }}>{this.state.UnidadeEquivalencia}</span>
                                </div>
                              </div>
                            </div>}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Responsável<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <div className="input-group">
                            <select disabled={this.state.estoque_disabled} value={this.state.Responsavel} className="form-control"
                              onChange={(e) => {
                                this.setState({ Responsavel: e.target.value })
                              }}>
                              <option value={''}>Selecione uma opção</option>
                              {this.state.Responsaveis.map((item, id) => (<option key={id} value={item.id}>{item.name}</option>))}
                            </select>
                            <UsuarioModal disabled={this.state.estoque_disabled} reload={(e) => this.get_data(e)} estoque_id={this.state.EstoqueArmazenamento} />
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>


                  {/*-------------- loadings -------------*/}
                  {this.state.loading_save == false &&
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start" style={{ marginBottom: '0.5rem' }}>
                        <Link to="/registro_gestao_interna"><span className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                      </div>
                      <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light" style={{ backgorundColor: '#f2f2f2' }} disabled={Boolean(this.state.QuantityError)}>Salvar entrada</button>
                      </div>
                    </div>}

                  {this.state.loading_save == true &&
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status" ></div>
                      </div>
                    </div>}




                </div>}
              {this.state.loading_screen == true &&
                <div className="row">
                  <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status" ></div>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }


}
const mapsStateToProps = (state) => (
  {
    token: state.AppReducer.token,
  }
);

export default connect(mapsStateToProps, { logout })(CriarRegistro);


