import React, { Component } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import {
  maskCep,
  maskCnpj,
  maskNumber,
  maskNumberBr,
  maskPrice,
} from '../../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NumericFormat } from 'react-number-format';
import UsuarioModal from '../../Cadastros/UsuarioModal/UsuarioModal';

class EditarSolicitacaoColeta extends Component {
  constructor(props) {
    let allMaterials = [];

    super(props);
    this.state = {
      redirect: false,
      path: '/solicitar_coleta',
      loading_screen: false,

      PontosColetas: [],
      Estoque: [],
      Materiais: [],
      Responsaveis: [],
      Todos_Responsaveis: [],
      MateriaisSelecionados: [],
      MateriaisIseridos: [],
      MaterialSelecionado: {},
      EstoqueSelecionado: {},
      Devolucao: [],
      Devolucao_selecionada: {},
      RegistroEntrada: [],

      Descricao: '',
      UnidadePeso: '',
      UnidadeVolume: '',
      Material: '',
      peso_disponivel: '',
      volume_disponivel: '',
      Peso: '',
      Volume: '',
      Peso_kg: '',
      Volume_lt: '',
      NomeMaterial: '',
      PontoColeta: '',
      DataAtual: '',
      DataSolicitacao: '',
      loading_save: false,
      msgErro: '',
      show_warning: false,
      validated: false,
      required: false,
      CodigoSolicitacao: '',
      createRecord: false,
      fixedRequest: false,
      clearStock: false,
      collectInterval: '',
    };
  }

  get_data(id) {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URL}api/get_editar_solicitar_coleta/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message == 'Unauthenticated.') {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            // this.setState({redirect:true,path:'/'});
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + '\n';
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ redirect: true });
          } else {
            let residuo_rejeito = resp.solicitation_data.solicitar_coleta_residuo_rejeito;
            let old_material = [];
            for (let i = 0; i < residuo_rejeito.length; i++) {
              let novo_material = {
                residuo_rejeito_id: residuo_rejeito[i].residuo_rejeito_id,
                nome: residuo_rejeito[i].residuo_rejeito.nome,
                unidade_peso:
                  residuo_rejeito[i].residuo_rejeito.unidade_principal.tipo ===
                    'Peso'
                    ? residuo_rejeito[i].residuo_rejeito.unidade_principal.sigla
                    : residuo_rejeito[i].residuo_rejeito.unidade_secundaria
                      .sigla,
                unidade_volume:
                  residuo_rejeito[i].residuo_rejeito.unidade_principal.tipo !==
                    'Peso'
                    ? residuo_rejeito[i].residuo_rejeito.unidade_principal.sigla
                    : residuo_rejeito[i].residuo_rejeito.unidade_secundaria
                      .sigla,
                peso: residuo_rejeito[i].clear_stock ? 0 : residuo_rejeito[i].peso,
                volume: residuo_rejeito[i].clear_stock ? 0 : residuo_rejeito[i].volume,
                peso_kg: residuo_rejeito[i].clear_stock ? 0 : residuo_rejeito[i].peso_kg,
                volume_lt: residuo_rejeito[i].clear_stock ? 0 : residuo_rejeito[i].volume_lt,
                create_record: residuo_rejeito[i].create_record,
                clear_stock: residuo_rejeito[i].clear_stock,
              };
              novo_material.label_peso = residuo_rejeito[i].clear_stock ? 'Limpar estoque' : (maskNumberBr(residuo_rejeito[i].peso + ' ') + novo_material.unidade_peso)
              novo_material.label_volume = residuo_rejeito[i].clear_stock ? 'Limpar estoque' : (maskNumberBr(residuo_rejeito[i].volume + ' ') + novo_material.unidade_volume)

              old_material = [...old_material];
              old_material.push(novo_material);
            }
            console.log(old_material);

            const collectPoint = resp.collect_points.find(item => item.id === resp.solicitation_data.ponto_coleta_id)
            console.log(collectPoint)
            const materials = resp.materials.filter(item => collectPoint?.materials.includes(item.id))
            const responsibles = resp.responsibles.filter(item => collectPoint?.users.includes(item.id))

            this.setState({
              loading_screen: false,
              PontosColetas: resp.collect_points,
              Todos_Responsaveis: resp.responsibles,
              DataSolicitacao: resp.solicitation_data.data,
              PontoColeta: resp.solicitation_data.ponto_coleta_id,
              Descricao:
                resp.solicitation_data.descricao == null
                  ? ''
                  : resp.solicitation_data.descricao,
              Estoque: resp.stocks,
              MateriaisSelecionados: old_material,
              Materiais: materials,
              Responsavel_id: resp.solicitation_data.responsavel_id,
              CodigoSolicitacao: resp.solicitation_data.codigo_solicitacao,
              Responsaveis: responsibles,
              RegistroEntrada: resp.solicitation_data.registro_entrada,
              fixedRequest: resp.solicitation_data.fixed_request,
              collectInterval: resp.solicitation_data.collect_interval,
            });

            this.allMaterials = resp.materials
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
        // this.props.mudarLoadingHome(false);
      });
  }

  salvar() {

    if (this.state.MaterialSelecionado.id != undefined) {
      this.setState({ show_warning: true, loading_save: false, msgErro: '' });
    } else {
      this.setState({ loading_save: true, msgErro: '', show_warning: false });
      let peso = 0;
      for (let i in this.state.MateriaisSelecionados) {
        peso += parseFloat(this.state.MateriaisSelecionados[i].peso_kg);
      };
      let volume = 0;
      for (let i in this.state.MateriaisSelecionados) {
        volume += parseFloat(this.state.MateriaisSelecionados[i].volume_lt);
      };

      console.log({
        data: this.state.DataSolicitacao,
        ponto_coleta_id: this.state.PontoColeta,
        responsavel_id: this.state.Responsavel_id,
        total_kg: peso,
        total_lt: volume,
        status: " Aguardando Despacho",
        solicitar_id: this.props.match.params.id,
        descricao: this.state.Descricao,
        solicitar_coleta_residuo_rejeito: this.state.MateriaisSelecionados,
        registro_entrada: this.state.RegistroEntrada

      })
      // this.setState({ loading_save: false });

      // return;
      fetch(`${URL}api/update_solicitar_coleta`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: this.state.DataSolicitacao,
          ponto_coleta_id: this.state.PontoColeta,
          responsavel_id: this.state.Responsavel_id,
          total_kg: peso,
          total_lt: volume,
          status: " Aguardando Despacho",
          solicitar_id: this.props.match.params.id,
          descricao: this.state.Descricao,
          solicitar_coleta_residuo_rejeito: this.state.MateriaisSelecionados,
          registro_entrada: this.state.RegistroEntrada,
          fixed_request: this.state.fixedRequest,
          collect_interval: this.state.collectInterval,
        })
      }).then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1)
                erro += errors[i] + '\n';
              else
                erro += errors[i];
            }
            console.log(erro);
            window.scrollTo(500, 0);
            this.setState({ loading_save: false, msgErro: erro });
          }
          else {

            this.setState({ loading_save: false, redirect: true });

          }

        } catch (err) {
          console.log(err);
          this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

        }

      })
        .catch((err) => {
          console.log(err);
          this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
        });
    }
  }

  handleChangeInterval = ({ target }) => {
    if (isNaN(target.value) || target.value < 0 || target.value > 366) {
      return
    }

    this.setState({ collectInterval: target.value })
  }

  componentDidMount() {
    let data = new Date();
    let dia = String(data.getDate()).padStart(2, '0');
    let mes = String(data.getMonth() + 1).padStart(2, '0');
    let ano = data.getFullYear();
    this.setState({
      DataAtual: ano + '-' + mes + '-' + dia
    })
    this.get_data(this.props.match.params.id);
  }



  render() {


    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}

        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">


              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Solicitação de Coleta</h4>
              <p style={{ fontSize: '1rem', color: 'black' }}>Edite uma  solicitação de coleta</p>
              <br />

              {/* <hr /> */}
              {this.state.loading_screen == false && <div>
                {this.state.msgErro != '' && <div>
                  <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                  </div>
                  <br />

                </div>}
                <div className="row">
                  <div className="col-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Data da solicitação <b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12 d-flex align-items-center">
                        <input className="form-control" value={this.state.DataSolicitacao} type="date" style={{ maxWidth: 168 }} min={this.state.DataAtual} onChange={(e) => {
                          if (e.target.value < this.state.DataAtual) {
                            e.target.value = this.state.DataAtual
                          }
                          this.setState({ DataSolicitacao: e.target.value });
                        }} />

                        <div className="ms-3">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={this.state.fixedRequest} checked={this.state.fixedRequest} onChange={() => this.setState({ fixedRequest: !this.state.fixedRequest })} id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                              Colexta fixa
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.fixedRequest &&
                      <div className="form-group row" style={{ maxWidth: 320 }}>
                        <label className="col-sm-12 col-form-label">Intervalo<b style={{ color: 'red' }}>*</b></label>
                        <div className="input-group">
                          <input type="text" className="form-control" value={this.state.collectInterval} onChange={this.handleChangeInterval} placeholder='Intervalo entre solicitações' />
                          <span className="input-group-text" id="basic-addon1">Dias</span>
                        </div>
                      </div>}

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Ponto de coleta<b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12">
                        <select value={this.state.PontoColeta} className="form-control"
                          onChange={(e) => {
                            const collectPoint = this.state.PontosColetas.find((item) => item.id === e.target.value)

                            if (!collectPoint) {
                              return this.setState({
                                Material: '',
                                PontoColeta: '',
                                Volume: '',
                                Peso: '',
                                required: false,
                                validated: false,
                                Materiais: [],
                                Responsaveis: [],
                                MateriaisSelecionados: [],
                                MaterialSelecionado: {},
                              })
                            }

                            const materials = this.allMaterials.filter(item => collectPoint.materials.includes(item.id))
                            const responsibles = this.state.Todos_Responsaveis.filter(item => collectPoint?.users.includes(item.id))
                            // Hours array
                            // const operation = collectPoint.dias.reduce((acc, item) => {
                            //   if (acc.dias.includes(item.dia)) {
                            //     return acc
                            //   }

                            //   let horariosComuns = collectPoint.dias.filter(d => d.dia === item.dia && d.funcionamento === item.funcionamento)

                            //   if (!horariosComuns.length) {
                            //     return acc
                            //   }

                            //   acc.dias.push(item.dia)
                            //   acc.horarios.push({
                            //     dia: item.dia,
                            //     funcionamento: horariosComuns.map(h => ({
                            //       hora_inicio: h.hora_inicio,
                            //       hora_fim: h.hora_fim
                            //     }))
                            //   })

                            //   return acc
                            // }, { hours: [], days: [] })

                            // Updating states
                            return this.setState({
                              required: false,
                              validated: false,
                              MaterialSelecionado: {},
                              MateriaisSelecionados: [],
                              Material: '',
                              PontoColeta: e.target.value,
                              Materiais: materials,
                              Volume: '',
                              Peso: '',
                              Responsaveis: responsibles,
                              // dias: operation.days,
                              // horarios: operation.hours
                            })
                          }}>
                          <option value={''}>Selecione uma opção</option>
                          {this.state.PontosColetas.map((item, id) => (<option key={id} value={item.id}>{item.name}</option>))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label" >Materiais <b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12">
                        <select value={this.state.Material} className="form-control" onChange={(e) => {
                          let verificador = false;
                          for (let i = 0; i < this.state.MateriaisSelecionados.length; i++) {
                            if (e.target.value == this.state.MateriaisSelecionados[i].residuo_rejeito_id) {
                              verificador = true;
                            }
                          }
                          if (verificador == true) {
                            this.setState({
                              required: false,
                              validated: false,
                              Material: '',
                              EstoqueSelecionado: {},
                              MaterialSelecionado: {},
                              Volume: '',
                              Peso: ''
                            })
                          } else {
                            if (e.target.value != '') {
                              let material_selecionado = this.state.Materiais.filter(item => item.id === e.target.value);
                              let estoque = this.state.Estoque.filter(item => item.ponto_coleta_id === this.state.PontoColeta && item.material_id == material_selecionado[0].id);
                              let devolucao_sel = [];
                              if (estoque.length > 0) {
                                devolucao_sel = this.state.Devolucao.filter(dev => dev.residuo_rejeito_id == estoque[0].material_id)
                              }
                              console.log(estoque);
                              this.setState({
                                required: false,
                                validated: false,
                                Material: e.target.value,
                                EstoqueSelecionado: estoque,
                                MaterialSelecionado: material_selecionado[0],
                                Devolucao_selecionada: devolucao_sel,
                                Volume: '',
                                Peso: '',
                                peso_disponivel: estoque.length == 0 ? 0 : devolucao_sel.length > 0 ? parseFloat(estoque[0].peso_disponivel) + parseFloat(devolucao_sel[0].peso) : estoque[0].peso_disponivel,
                                volume_disponivel: estoque.length == 0 ? 0 : devolucao_sel.length > 0 ? parseFloat(estoque[0].volume_disponivel) + parseFloat(devolucao_sel[0].volume) : estoque[0].volume_disponivel,
                              })
                            } else {
                              this.setState({
                                required: false,
                                validated: false,
                                Material: '',
                                EstoqueSelecionado: {},
                                MaterialSelecionado: {},
                                Volume: '',
                                Peso: ''
                              })
                            }
                          }

                        }}>
                          <option value={''}>Selecione um ou mais materias</option>
                          {this.state.Materiais.map((item, id) => (<option key={id} value={item.id}>{item.name}</option>))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row" style={{ marginTop: '30px' }}>
                      <p className="col-sm-6 "> <b className="col-sm-4 ">Código : </b> {this.state.CodigoSolicitacao} </p>
                    </div>
                    {/* se material existir */}
                    {this.state.MaterialSelecionado.id != undefined && <>
                      <hr />
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label" style={{ color: 'red', textTransform: 'uppercase' }}>{this.state.MaterialSelecionado.nome}</label>
                        <div className="form-group  row">
                          <div className=" col-sm-12">
                            {/* se medida principal  em peso */}
                            {this.state.MaterialSelecionado.main_unity.tipo == 'Peso' &&
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                  <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon3">Disponível:{maskNumberBr(this.state.peso_disponivel)}{' ' + this.state.MaterialSelecionado.main_unity.sigla}</span>
                                    <NumericFormat className="form-control " id="peso"
                                      valueIsNumericString
                                      thousandsGroupStyle='thousand'
                                      thousandSeparator="."
                                      decimalScale={2}
                                      decimalSeparator=","
                                      placeholder="peso do material"
                                      type="text"
                                      value={this.state.Peso}
                                      onValueChange={(values, e) => {
                                        console.log(this.state.Devolucao_selecionada);
                                        console.log(values.value);
                                        let equivalencia = this.state.MaterialSelecionado.equivalence;
                                        this.setState({
                                          required: false,
                                          validated: false,
                                          Volume: equivalencia * values.value,
                                          Peso: values.value,
                                          UnidadePeso: this.state.MaterialSelecionado.main_unity.sigla,
                                          UnidadeVolume: this.state.MaterialSelecionado.secondary_unity.sigla,
                                          Peso_kg: values.value * this.state.MaterialSelecionado.main_unity.equivalencia,
                                          Volume_lt: (equivalencia * values.value) * this.state.MaterialSelecionado.secondary_unity.equivalencia
                                        })
                                      }} />
                                  </div>
                                  {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Peso é obrigatório</b>}
                                </div>
                                <div className="col-md-6 col-12">
                                  <label className="col-sm-12 col-form-label">Volume<b style={{ color: 'red' }}>*</b></label>
                                  <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon3">Disponível: {maskNumberBr(this.state.volume_disponivel)}{' ' + this.state.MaterialSelecionado.secondary_unity.sigla}</span>
                                    <NumericFormat className="form-control" id="volume"
                                      disabled
                                      valueIsNumericString
                                      thousandsGroupStyle='thousand'
                                      thousandSeparator="."
                                      decimalScale={2}
                                      decimalSeparator=","
                                      placeholder="volume do material"
                                      type="text" onValueChange={(values, e) => this.setState({ Volume: values.value })}
                                      value={this.state.Volume} />
                                  </div>
                                </div>
                              </div>}
                            {/* se medida principal for em volume */}
                            {this.state.MaterialSelecionado.main_unity.tipo !== 'Peso' &&
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label className="col-sm-12 col-form-label">{this.state.MaterialSelecionado.main_unity.tipo}<b style={{ color: 'red' }}>*</b></label>
                                  <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon3">Disponível: {maskNumberBr(this.state.volume_disponivel)}{' ' + this.state.MaterialSelecionado.main_unity.sigla}</span>
                                    <NumericFormat className="form-control" id="volume"
                                      placeholder={`${this.state.MaterialSelecionado.main_unity.tipo.toLowerCase()} do material`} type="text"
                                      valueIsNumericString
                                      thousandsGroupStyle='thousand'
                                      thousandSeparator="."
                                      decimalScale={2}
                                      decimalSeparator=","
                                      value={this.state.Volume}
                                      onValueChange={(values, e) => {
                                        let equivalencia = this.state.MaterialSelecionado.equivalence;
                                        this.setState({
                                          required: false,
                                          validated: false,
                                          Peso: equivalencia * values.value,
                                          Volume: values.value,
                                          UnidadePeso: this.state.MaterialSelecionado.secondary_unity.sigla,
                                          UnidadeVolume: this.state.MaterialSelecionado.main_unity.sigla,
                                          Volume_lt: values.value * this.state.MaterialSelecionado.main_unity.equivalencia,
                                          Peso_kg: (equivalencia * values.value) * this.state.MaterialSelecionado.secondary_unity.equivalencia
                                        })
                                      }} />
                                  </div>
                                  {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Volume é obrigatório</b>}
                                </div>
                                <div className="col-md-6 col-12">
                                  <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                  <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon3">Disponível:{maskNumberBr(this.state.peso_disponivel)}{' ' + this.state.MaterialSelecionado.secondary_unity.sigla}</span>
                                    <NumericFormat className="form-control" id="peso"
                                      disabled placeholder="peso do material"
                                      type="text"
                                      valueIsNumericString
                                      thousandsGroupStyle='thousand'
                                      thousandSeparator="."
                                      decimalScale={2}
                                      decimalSeparator=","
                                      onValueChange={(values, e) => this.setState({ Peso: values.value })} value={this.state.Peso} />
                                  </div>
                                </div>
                              </div>}

                            <div className='d-flex align-items-center'>
                              {this.state.fixedRequest &&
                                <div>
                                  <label className="col-sm-12 col-form-label m-0">Para as próximas coletas: <b style={{ color: 'red' }}>*</b></label>
                                  <div className="d-flex">
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" value={!this.state.createRecord} checked={!this.state.createRecord && !this.state.clearStock} onChange={() => this.setState({ createRecord: false, clearStock: false })} id="dontCreateRecords" />
                                      <label className="form-check-label" htmlFor="dontCreateRecords">
                                        Utilizar apenas material disponível
                                      </label>
                                    </div>

                                    <div className="form-check ms-3">
                                      <input className="form-check-input" type="checkbox" value={this.state.createRecord} checked={this.state.createRecord && !this.state.clearStock} onChange={() => this.setState({ createRecord: true, clearStock: false })} id="createRecords" />
                                      <label className="form-check-label" htmlFor="createRecords">
                                        Criar registros se necessário
                                      </label>
                                    </div>

                                    <div className="form-check ms-3">
                                      <input className="form-check-input" type="checkbox" value={this.state.clearStock} checked={this.state.clearStock && !this.state.createRecord} onChange={() => this.setState({ clearStock: true, createRecord: false, Peso: 'Limpar estoque', Volume: 'Limpar Estoque' })} id="clearStocks" />
                                      <label className="form-check-label" htmlFor="clearStocks">
                                        Limpar estoque
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              }


                              <div className="align-self-end ms-auto">
                                <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={() => {
                                  let { Peso, Volume } = this.state
                                  if (this.state.clearStock) {
                                    Peso = 'Limpar estoque'
                                    Volume = 'Limpar estoque'
                                  }

                                  if (Peso == '' || Volume == '') {
                                    this.setState({ required: true });
                                    return
                                  }

                                  let novoMaterial = {
                                    'residuo_rejeito_id': this.state.Material,
                                    'nome': this.state.MaterialSelecionado.name,
                                    'unidade_peso': this.state.UnidadePeso,
                                    'unidade_volume': this.state.UnidadeVolume,
                                    'label_peso': isNaN(Peso)
                                      ? Peso
                                      : (maskNumberBr(Peso + ' ') + this.state.UnidadePeso),
                                    'label_volume': isNaN(Volume)
                                      ? Volume
                                      : (maskNumberBr(Volume + ' ') + this.state.UnidadeVolume),
                                    'peso': isNaN(Peso) ? 0 : Peso,
                                    'volume': isNaN(Volume) ? 0 : Volume,
                                    'peso_kg': this.state.Peso_kg ?? 0,
                                    'volume_lt': this.state.Volume_lt ?? 0,
                                    'create_record': this.state.createRecord,
                                    'clear_stock': this.state.clearStock,
                                  };

                                  let materiaisInseridos = [...this.state.MateriaisIseridos, this.state.MaterialSelecionado];
                                  let materiaisSelecionados = [...this.state.MateriaisSelecionados, novoMaterial];

                                  this.setState({
                                    Material: '',
                                    MaterialSelecionado: '',
                                    MateriaisSelecionados: materiaisSelecionados,
                                    MateriaisIseridos: materiaisInseridos,
                                  });
                                }
                                }>Inserir</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    }
                    {this.state.MateriaisSelecionados.length != 0 && <>
                      <hr />
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }} >Materiais selecionados:</label>

                        <table id="tech-companies-1" className="table table-striped table-hover" >
                          <thead>
                            <tr>
                              <th>Material</th>
                              <th>Peso</th>
                              <th>Volume</th>
                              <th data-priority="3">Excluir</th>
                            </tr>
                          </thead>
                          <tbody>


                            {this.state.MateriaisSelecionados.map((item, id) => (
                              <tr key={id}>
                                <td>{item.nome}</td>
                                <td>{item.label_peso}</td>
                                <td>{item.label_volume}</td>
                                <td>
                                  <button onClick={() => {
                                    let materiais_selecionados = [...this.state.MateriaisSelecionados];
                                    materiais_selecionados = materiais_selecionados.filter((item3, id3) => item3.residuo_rejeito_id != item.residuo_rejeito_id);
                                    let material_inserido = [...this.state.MateriaisIseridos];
                                    material_inserido = material_inserido.filter((item2, id2) => item2.id != item.id);

                                    let ab = this.state.MateriaisSelecionados.filter((mat, id4) => mat.residuo_rejeito_id == item.residuo_rejeito_id);
                                    let devolucao = [...this.state.Devolucao];
                                    devolucao.push(ab[0]);
                                    let registro = this.state.RegistroEntrada.filter((item4) => item4.residuo_rejeito_id != item.residuo_rejeito_id);

                                    console.log(registro)
                                    console.log(devolucao);
                                    this.setState({
                                      MateriaisSelecionados: materiais_selecionados,
                                      MateriaisIseridos: material_inserido,
                                      Devolucao: devolucao,
                                      RegistroEntrada: registro
                                    });
                                  }}
                                    className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', }}><FaTrash color='red' /></button>
                                </td>
                              </tr>
                            ))}

                          </tbody>
                        </table>


                      </div>
                      <hr />
                    </>}
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Descrição </label>
                      <div className="col-sm-12">
                        <textarea className="form-control" placeholder="Descrição" value={this.state.Descricao} type="text" onChange={(e) => { this.setState({ Descricao: e.target.value }) }} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Responsável <b style={{ color: 'red' }}>*</b>
                      </label>
                      <div className="col-sm-12">
                        <div className="input-group">
                          <select
                            value={this.state.Responsavel_id}
                            disabled={this.state.PontoColeta == ''}
                            className="form-control"
                            id="cameras"
                            onChange={(e) => {
                              this.setState({
                                Responsavel_id: e.target.value,
                              });
                            }}
                          >
                            <option value={''}>
                              Selecione um responsável
                            </option>
                            {this.state.Responsaveis.map((item, id) => (
                              <option key={id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <UsuarioModal
                            disabled={this.state.PontoColeta == ''}
                            reload={(e) => this.get_data(e)}
                            id={this.props.match.params.id}
                            ponto_id={this.state.PontoColeta}
                          />
                        </div>
                      </div>
                    </div>



                  </div>

                  {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                </div>
                {this.state.loading_save == false && <div className="row mt-3">
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                    style={{ marginBottom: '0.5rem' }}>
                    <Link to="/solicitar_coleta"> <span
                      className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                  </div>
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                    style={{ marginBottom: '0.5rem' }}>
                    <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                      style={{ backgorundColor: '#f2f2f2' }}>Salvar solicitação</button>
                  </div>
                </div>}
                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                  <div className="spinner-border text-primary" role="status"  >
                  </div>
                </div></div>}
              </div>}

              {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                <div className="spinner-border text-primary" role="status"  >
                </div>
              </div></div>}

              <SweetAlert
                warning
                title={"Atenção"}
                onConfirm={() => { this.setState({ MaterialSelecionado: {}, Material: '' }, () => this.salvar()) }}
                onCancel={() => { this.setState({ show_warning: false }); }}
                show={this.state.show_warning}
                confirmBtnText='Sim, desejo salvar '
                cancelBtnText='Cancelar'
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                showCancel={true}
              >
                Existe um material selecionado que não foi inserido, deseja salvar mesmo assim ?
              </SweetAlert>
            </div>

          </div>
        </div>
      </div>
    );
  }


}
const mapsStateToProps = (state) => (
  {
    token: state.AppReducer.token,
    user: state.AppReducer.user
  }
);

export default connect(mapsStateToProps, { logout })(EditarSolicitacaoColeta);