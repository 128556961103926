
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { NAME, URL, URLopen } from '../../../variables';
import { maskDate, maskHour, maskMoneyV2, maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';
import SweetAlert from 'react-bootstrap-sweetalert';

import { MdInfo, MdReply } from 'react-icons/md';
import { GET, PATCH, PUT } from '../../../Auxiliar/Requests';
import { NumericFormat } from 'react-number-format';
import { BsCircleFill } from 'react-icons/bs';
import MotoristaModal from '../../Modais/MotoristaModal';
import VeiculoModal from '../../Modais/VeiculoModal';
import TransportadoraModal from '../../Modais/TransportadoraModal';
import { Alert, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { handleStatus } from '../../../Auxiliar/utilities';
import FileInput from '../../../Auxiliar/FileInput';
import ControlMtr from '../../../Home/ColetaTrasporte/MTR/ControlMtr';
import { toast, ToastContainer } from 'react-toastify';
import BuscarCDF from './components/BuscarCDF';
import CancelMTRModal from '../../Modais/CancelMTRModal';


class ViewDestinacaoInterna extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/destinacao_interna',
            loading_screen: false,
            DestinacaoAtual: [],
            Destinos: [],
            Estoques: [],
            AllEstoques: [],
            EstoqueInterno: [],
            AllMateriais: [],
            Materiais: [],
            MateriaisInseridos: [],
            Transportadoras: [],
            Veiculos: [],
            Motoristas: [],
            Destinacoes: [],
            Tratamentos: [],
            Devolucao: [],
            DevolucaoSelecionada: [],
            DestinoSelecionado: '',
            EstoqueSelecionado: '',
            Materialselecionado: '',
            VeiculoSelecionado: '',
            MotoristaSelecionado: '',
            TransportadoraSelecionada: '',
            DataFinalizado: '',
            EstoqueNome: '',
            TransportadoraOpen: '',
            VeiculoOpen: '',
            MotoristaOpen: '',
            DestinoNome: '',
            Tratamento: '',
            UnidadePeso: '',
            UnidadeVolume: '',
            Peso_kg: '',
            Volume_lt: '',
            Movimentacao: '',
            ValorFinanceiro: '',
            DestinacaoId: '',
            Descricao: '',
            ChaveNota: '',
            NumeroNota: '',
            SerieNota: '',
            AnexoFiscal: null,
            NameAnexo: null,
            pendenciaFiscal: false,
            mtr: null,
            Cdf: null,
            pdf: '',
            TransportadoraId: '',
            VeiculoId: '',
            MotoristaId: '',
            PesoTotal: '0',
            VolumeTotal: '0',
            ReceitaTotal: '0.00',
            DespesaTotal: '0.00',
            Saldo: '0.00',
            Situacao: 'Rascunho',
            DataAgendamento: '',
            DataAtual: '',
            DataRecebido: '',
            DataColeta: '',
            DataCriacao: '',
            Peso: '',
            Volume: '',
            MaterialId: '',
            Destino: '',
            EstoqueId: '',
            loading_save: false,
            msgErro: '',
            required: false,
            validated: false,
            show_warning: false,
            CollectSolicitationOpen: '',
            CanGenerateCollect: false,
            CollectLoading: false,
            transportOpen: false,
            expedientData: '',
            appointmentHour: "",
            workToday: false,
            dailyHours: [],
            numericWeekdays: [],
            hasWorkingData: false,
            active: [],
            canceled: [],
            codigoManifesto: '',
            pagination: '',
            pageNumber: 1,
            loading: true,
            loadingSearch: false,
            reload: false,
            search: '',
            showWarningCancel: false,
            loadingCancel: false,
            mtrToCancel: '',
            showWarningDelete: false,
            loadingDelete: false,
            mtrToDelete: '',
            modalOpen: false,
            selectedMtrId: null,
            searchCDF: {
                loading: false,
                error: null,
                value: ''
            },
            StaticAnnex: null,
            processarReprocessarMTR: false,
            show_warning_cdf: false,
            show_warning_desvincular_cdf: false
        };
    }

    download(blob, filename) {

        const mimeType = blob.type;
        if (mimeType === 'application/pdf') {
            console.log('O arquivo é um PDF.');

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } else if (mimeType === 'text/plain') {
            console.log('O arquivo é um TXT.');
        } else {
            console.log('O arquivo é de outro tipo: ' + mimeType);
        }
    }

    processMTR = async (id) => {

        try {
            const response = await fetch(`${URL}api/post_processar_MTR_por_Destinacaoid`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.props.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    destinacaoId: id
                })
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Metódo executado:', data);
                return { mtr: data.mtr, error: null };
            } else {
                const error = await response.text();
                console.error('Erro ao gerar MTR:', error);
                return { mtr: null, error };
            }
        } catch (error) {
            console.error('Erro ao gerar MTR:', error);
            return { mtr: null, error };
        }
        finally {
            // window.location.reload();
        }
    };

    processCDF = async (id) => {
        try {
            const response = await fetch(`url_geracao_cdf`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mtrId: id,
                })
            });

            if (response.ok) {
                const data = await response.json();
                console.log('CDF gerado com sucesso:', data);
                return { cdf: data.cdf, error: null };
            } else {
                const error = await response.text();
                console.error('Erro ao gerar CDF:', error);
                return { cdf: null, error };
            }
        } catch (error) {
            console.error('Erro ao gerar CDF:', error);
            return { cdf: null, error };
        }
        finally {
        }
    };

    handleOpenModal = (mtrId) => {
        this.setState({ modalOpen: true, selectedMtrId: mtrId });
    };

    handleCloseModal = (e) => {
        if (e == true) {
            this.get_data();
        }
        this.setState({ modalOpen: false, selectedMtrId: null });
    };

    getMtr = async (codigo_manifesto_numero_nacional) => {
        return fetch(`${URL}api/get_download_mtr/${codigo_manifesto_numero_nacional}`, {
            method: 'GET',
            headers: {
                Accept: 'application/pdf',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/pdf',
            }
        })
            .then(async (response) => {


                response.blob().then(blob => this.download(blob, codigo_manifesto_numero_nacional))
            })
    }
    getCdf = async (codigo_cdf) => {

        return fetch(`${URL}api/get_download_cdf/${codigo_cdf}`, {
            method: 'GET',
            headers: {
                Accept: 'application/pdf',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/pdf',
            }
        })
            .then(async (response) => {

                let blob = await response.blob();

                if (this.state.Cdf == null && blob.type === 'application/pdf') {
                    this.setState({ show_warning_cdf: true });
                }
                if (blob.type === 'application/pdf') {
                    toast.success("CDF encontrado!");

                } else
                    toast.error("CDF não encontrado!");

                this.download(blob, codigo_cdf);

            })
    }
    handleCDFNumber = (e) => {
        this.setState({
            searchCDF: {
                loading: false,
                error: null,
                value: e.target.value
            }
        });
    }

    findCDF = async () => {
        if (!this.state.searchCDF.value || this.state.searchCDF.value == '') {
            this.setState({
                searchCDF: {
                    loading: false,
                    error: "Digite o número do CDF para buscá-lo...",
                    value: ''
                }
            });
            return;
        }

        this.setState({
            searchCDF: {
                loading: true,
                error: null,
                value: this.state.searchCDF.value
            }
        });

        this.getCdf(this.state.searchCDF.value);
        console.log('número CDF::', this.state.searchCDF.value)
        return;
    }
    vincularCdfaDestinacaoInterna = async () => {

        if (this.state.searchCDF.value != '') {

            return fetch(`${URL}api/post_vincular_cdf_a_destinacao_interna`, {
                method: 'POST',
                headers: {
                    Accept: 'application/pdf',
                    'Authorization': `Bearer ${this.props.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    destinacaoId: this.state.DestinacaoAtual.destinacao_interna_id,
                    cdf: this.state.searchCDF.value
                }),
            })
                .then(async (responseLog) => {
                    try {

                        let resp = await responseLog.json();
                        if (resp.errors != null) {
                            toast.warning('Erro ao CDF a destinacao Interna!');
                            this.setState({
                                show_warning_cdf: false
                            });
                        }
                        else {


                            this.setState({
                                show_warning_cdf: false,
                                Cdf: { numeroCDF: this.state.searchCDF.value }
                            });

                            toast.success('CDF vinculado com sucesso');
                        }
                    } catch (err) {
                        console.log(err);
                        toast.warning('Erro ao vincular CDF!');
                        this.setState({
                            show_warning_cdf: false
                        });
                    }

                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
                });
        }
    }
    desvincularCdfaDestinacaoInterna = async () => {

        return fetch(`${URL}api/post_desvincular_cdf_a_destinacao_interna`, {
            method: 'POST',
            headers: {
                Accept: 'application/pdf',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                destinacaoId: this.state.DestinacaoAtual.destinacao_interna_id
            }),
        })
            .then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    if (resp.errors != null) {
                        toast.warning('Erro ao desvincular CDF!');
                        this.setState({
                            show_warning_desvincular_mtr: false
                        });
                    }
                    else {

                        this.setState({

                            Cdf: null
                        });
                        this.setState({
                            show_warning_desvincular_cdf: false
                        });
                        toast.success('CDF vinculado com sucesso');
                    }
                } catch (err) {
                    console.log(err);
                    toast.warning('Erro ao desvincular MTR!');
                    this.setState({
                        show_warning_desvincular_mtr: false
                    });
                }

            })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }
    get_data() {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }

        fetch(`${URL}api/get_editar_destinacao_interna/${this.props.match.params.id}/${NAME}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            // try {
            let resp = await responseLog.json();
            console.log(resp);
            if (resp.message == "Unauthenticated.") {
                localStorage.removeItem('token'); localStorage.removeItem('user');
                // this.setState({redirect:true,path:'/'});
                this.props.logout();
                return;
            }
            if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                    if (i != errors.length - 1)
                        erro += errors[i] + '\n';
                    else
                        erro += errors[i];
                }
                console.log(erro);
                this.setState({ redirect: true });
            }
            else {
                let transportadora = ''

                if (resp.destinacao?.transporte) {
                    transportadora = !resp.destinacao?.transporte?.transportadora && resp.destinacao?.transporte?.veiculo ? 'proprio' : resp.destinacao?.transporte?.transportadora?.id
                    if (transportadora == 'proprio') {
                        this.proprio = true
                    }
                }

                let pickup_data = {}
                if (resp.pickup_data) {
                    const day = resp.destinacao?.data_agendamento ? new Date(resp.destinacao.data_agendamento).getUTCDay() : null
                    const numericDays = resp.pickup_data.map(item => Number(item.numeric_day))

                    console.log('numeric', numericDays)

                    pickup_data = {
                        expedientData: resp.pickup_data,
                        numericWeekdays: numericDays,
                        workToday: numericDays.includes(new Date(resp.destinacao.data_agendamento).getUTCDay()),
                        dailyHours: (day && numericDays.includes(day) && !resp?.collect_solicitation_open?.appointment_start_hour)
                            ? resp?.pickup_data?.filter(item => Number(item.numeric_day) === day)[0]?.period
                            : [],
                        appointmentHour: resp?.collect_solicitation_open?.appointment_start_hour ? {
                            start_time: resp?.collect_solicitation_open?.appointment_start_hour,
                            closing_time: resp?.collect_solicitation_open?.appointment_end_hour,
                        } : null,
                        hasWorkingData: true,
                    }
                } else {
                    pickup_data = {
                        expedientData: null,
                        numericWeekdays: null,
                        workToday: true,
                        hasWorkingData: false,
                        dailyHours: [],
                        appointmentHour: null,
                    }
                }

                const { key = null, number = null, serie = null, document = null } = resp.destinacao.invoice || {}

                this.setState({
                    MateriaisInseridos: resp.destinacao.materiais,
                    loading_screen: false,
                    Transportadoras: resp.transportadoras,
                    Veiculos: resp.veiculos,
                    Motoristas: resp.motoristas,
                    transportOpen: resp.destinacao?.transporte?.is_open ? true : false,

                    EstoqueId: resp.destinacao.estoque.id,
                    DestinacaoAtual: resp.destinacao,
                    TransportadoraId: transportadora,
                    Situacao: resp.destinacao.situacao,
                    Descricao: resp.destinacao.descricao != null ? resp.destinacao.descricao : '',
                    Destino: resp.destinacao.destino.id,
                    DataSolicitacao: resp.destinacao.data_solicitacao,
                    DataAgendamento: resp.destinacao.data_agendamento != null ? resp.destinacao.data_agendamento : '',
                    DataRecebido: resp.destinacao.data_recebimento != null ? resp.destinacao.data_recebimento : '',
                    DataColeta: resp.destinacao.data_coleta != null ? resp.destinacao.data_coleta : '',
                    PesoTotal: resp.destinacao.peso_total,
                    VolumeTotal: resp.destinacao.volume_total,
                    ReceitaTotal: resp.destinacao.receita_total,
                    DespesaTotal: resp.destinacao.despesa_total,
                    Saldo: resp.destinacao.saldo,
                    MotoristaId: resp.destinacao.transporte?.motorista?.id,
                    VeiculoId: resp.destinacao.transporte?.veiculo?.id,
                    pendenciaFiscal: resp.destinacao.pendencia_fiscal,
                    ChaveNota: key ?? resp.destinacao.chave_nota,
                    NumeroNota: number ?? resp.destinacao.numero_nota,
                    SerieNota: serie ?? resp.destinacao.serie_nota,
                    AnexoNota: document,
                    DataCriacao: resp.destinacao.data_criacao,
                    DestinoNome: resp.destinacao.destino.fantasia,
                    EstoqueNome: resp.destinacao.estoque.nome_fantasia,
                    mtr: resp.mtr,
                    Cdf: resp.cdf,

                    StaticAnnex: document,

                    transport: resp.destinacao?.transporte,
                    CollectSolicitationOpen: resp.collect_solicitation_open,
                    CanGenerateCollect: resp.destinacao.can_generate_collect,
                    statusUser: this.getStatusObject(resp.destinacao.status || false, resp?.collect_solicitation_open?.status_user || false),
                    ...pickup_data,
                    processarReprocessarMTR: resp.destinacao.data_recebimento != null ? false : true
                });


            }
        })
    }

    getStatusObject = (status, statusOpen) => {
        if (!status && !statusOpen) return null

        const obj = statusOpen && (!status || status.created_at < statusOpen.created_at) ? statusOpen : status

        if (!obj || !obj.description || !obj.user || !obj.created_at) return null

        return {
            message: obj.description,
            user: obj.user.name,
            created_at: maskDate(obj.created_at),
            hour: maskHour(obj.created_at)
        }
    }

    finalizar() {
        this.setState({ loading_save: true, msgErro: '' });

        fetch(`${URL}api/finalizar_destinacao_interna`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                destinacao_interna_id: this.props.match.params.id,
                estoque_id: this.state.EstoqueId,
                destino_id: this.state.Destino,
                data_criacao: this.state.DataCriacao,
                data_agendamento: this.state.DataAgendamento ? this.state.DataAgendamento : this.state.DataFinalizado, //Cliente optou por salvar valores baseados na data finalizado quando datas forem vazias
                data_recebimento: this.state.DataRecebido ? this.state.DataRecebido : this.state.DataFinalizado,
                data_coleta: this.state.DataColeta ? this.state.DataColeta : this.state.DataFinalizado,
                data_finalizado: this.state.DataFinalizado,
                peso_total: this.state.PesoTotal,
                volume_total: this.state.VolumeTotal,
                receita_total: this.state.ReceitaTotal,
                despesa_total: this.state.DespesaTotal,
                saldo: this.state.Saldo,
                descricao: this.state.Descricao,
                situacao: 'Finalizado',
                materiais: this.state.MateriaisInseridos,
                pendencia_fiscal: this.state.pendenciaFiscal,
                key: this.state.pendenciaFiscal === true ? "" : this.state.ChaveNota,
                number: this.state.pendenciaFiscal === true ? "" : this.state.NumeroNota,
                serie: this.state.pendenciaFiscal === true ? "" : this.state.SerieNota,
                document: this.state.pendenciaFiscal === true && this.state.NameAnexo !== null ? null : this.state.AnexoNota,

                appointment_start_hour: this.state?.appointmentHour?.start_time,
                appointment_end_hour: this.state?.appointmentHour?.closing_time,

                driver_id: this.state.MotoristaId ?? null,
                vehicle_id: this.state.VeiculoId ?? null,
                carrier_id: this.state.TransportadoraId ?? null,
                transport_open: this.state.transportOpen,

                blueen_responsible_id: this.props.user.id,
                connection: NAME
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    this.setState({ loading_save: false, redirect: true });
                    if (this.state.CollectSolicitationOpen) {
                        fetch(`${URLopen}api/auth/blueen/notification/store`, {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                connection: NAME,
                                destination_blueen_id: this.state.Destino,
                                theme: 'collect_finished',
                                path_helper: 'ver', //*direcionando o usuário open para a tela correta
                                collect_id: this.state.CollectSolicitationOpen.id,
                                blueen_responsible_id: this.props.user.id,
                            })
                        })
                    }
                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });

    }



    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            DataSolicitacao: ano + '-' + mes + '-' + dia
        })
        this.get_data();
    }

    solicitarColetaOpen() {
        this.setState({ CollectLoading: true, msgErro: '' });

        fetch(`${URLopen}api/auth/blueen/solicitation/store`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                //* Flags / IDs
                blueen_destino_id: this.state.Destino,
                blueen_destinacao_id: this.state.DestinacaoAtual.id,
                blueen_responsible_id: this.props.user.id,
                connection: NAME,

                //* Collect data
                started_date: this.state.DataAgendamento,
                appointment_date: this.state.DataAgendamento,
                receiving_date: this.state.DataRecebido,
                collect_date: this.state.DataColeta,
                finalization_date: this.state.DataFinalizado,
                total_weight: this.state.PesoTotal,
                total_volume: this.state.VolumeTotal,
                income: this.state.ReceitaTotal,
                expend: this.state.DespesaTotal,
                balance: this.state.Saldo,
                invoice_number: this.state.DestinacaoAtual.numero_nota,
                invoice_series: this.state.DestinacaoAtual.serie_nota,
                invoice_key: this.state.DestinacaoAtual.chave_nota,
                invoice_pendency: this.state.pendenciaFiscal,
                document: this.state.StaticAnnex,

                wastes_rejects: this.state.MateriaisInseridos,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (!resp.status) {
                    console.log(resp.message);
                    window.scrollTo(500, 0);
                    this.setState({ CollectLoading: false, msgErro: resp.message });
                }
                else {
                    const day = new Date(this.state.DataAgendamento).getUTCDay()

                    if (!this.state.numericWeekdays.includes(day)) {
                        this.setState({
                            CollectLoading: false,
                            DataAgendamento: "",
                            appointmentHour: "",
                            dailyHours: [],
                            workToday: false,
                            CollectSolicitationOpen: resp.data
                        });
                    } else {
                        this.state.expedientData.map((item, id) => {
                            if (item.numeric_day == day) {
                                this.setState({
                                    CollectLoading: false,
                                    appointmentHour: "",
                                    dailyHours: item.period,
                                    workToday: true,
                                    CollectSolicitationOpen: resp.data
                                });
                            }
                        });
                    }
                }

            } catch (err) {
                console.log(err);
                this.setState({ CollectLoading: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ CollectLoading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    getStatus = () => {
        let status = 'Rascunho'
        if (this.state.Situacao == 'Cancelada') status = 'Cancelada'
        else if (this.state.DataAgendamento) status = 'Agendado'
        else if (this.state.DataColeta) status = 'Coletado'
        else if (this.state.DataRecebido) status = 'Recebido'
        else if (this.state.DataFinalizado) status = 'Finalizado'
        return status
    }

    handleValidateData = () => {
        let errors = []

        let validateResRej = this.state.MateriaisInseridos.filter(
            (item) => item.peso_kg <= 0 || item.volume_lt <= 0
        );

        if (validateResRej.length > 0) errors = [...errors, 'Existem resíduos com valores zerados']
        const transport = [this.state.TransportadoraId, this.state.VeiculoId, this.state.MotoristaId]
        if (this.state.DataFinalizado) errors = [...errors, 'Ao selecionar uma data de finalização, finalize a destinação']
        if (this.state.CollectSolicitationOpen && (this.state.DataAgendamento && this.state.hasWorkingData && !this.state.appointmentHour)) errors = [...errors, 'Ao agendar uma data é necessário escolher um horário disponível \n']
        const anyFiscalValueIsNull = this.state.ChaveNota === "" || this.state.SerieNota === "" || this.state.NumeroNota === "";
        if (!this.state.pendenciaFiscal && anyFiscalValueIsNull) errors = [...errors, 'Preencha os dados fiscais corretamente ou selecione que a destinação terá pendências fiscais']
        if (transport.some(item => Boolean(item)) !== transport.every(item => Boolean(item))) errors = [...errors, 'Em caso de transporte, escolha a transportadora, motorista e veículo \n']
        if (errors.length > 0) {
            window.scrollTo(500, 0);
            this.setState({ msgErro: errors })
            return false
        } else {
            return true
        }
    }

    async handleUpdateMiddleware() {
        const isValid = this.handleValidateData()
        if (isValid) {
            this.setState({ loading_save: true })
            const body = JSON.stringify({
                destinacao_interna_intermediaria_id: this.state.DestinacaoAtual.id,
                destinacao_id: this.props.match.params.id,
                data_criacao: this.state.DataCriacao,
                data_agendamento: this.state.DataAgendamento,
                data_recebimento: this.state.DataRecebido,
                data_coleta: this.state.DataColeta,
                peso_total: this.state.PesoTotal,
                volume_total: this.state.VolumeTotal,
                receita_total: this.state.ReceitaTotal,
                despesa_total: this.state.DespesaTotal,
                saldo: this.state.Saldo,
                driver_id: this.state.MotoristaId ?? null,
                vehicle_id: this.state.VeiculoId ?? null,
                carrier_id: this.state.TransportadoraId === 'proprio' ? '' : this.state.TransportadoraId ?? null,
                transport_open: this.state.transportOpen,
                descricao: this.state.Descricao,
                materiais: this.state.MateriaisInseridos,
                pendencia_fiscal: this.state.pendenciaFiscal,
                key: this.state.ChaveNota,
                number: this.state.NumeroNota,
                serie: this.state.SerieNota,
                document: this.state.AnexoNota,
                situacao: this.getStatus(),

                // appointment_date: this.state.DataAgendamento,
                appointment_start_hour: this.state?.appointmentHour?.start_time,
                appointment_end_hour: this.state?.appointmentHour?.closing_time,

                blueen_responsible_id: this.props.user.id,
                connection: NAME,
            })
            const response = await PATCH({ url: 'update_destinacao_interna_intermediaria', body, blueenOpen: false })
            this.setState({ loading_save: false, redirect: true });

            if (this.state.CollectSolicitationOpen) {
                fetch(`${URLopen}api/auth/blueen/notification/store`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        connection: NAME,
                        destination_blueen_id: this.state.Destino,
                        theme: 'collect_updated_by_blueen',
                        path_helper: 'confirmar', //*direcionando o usuário open para a tela correta
                        collect_id: this.state.CollectSolicitationOpen.id,
                        blueen_responsible_id: this.props.user.id,
                    })
                })
            }
        }
    }

    handleStatusCircle() {
        if (this.state.CollectSolicitationOpen.status == 0) {
            return <BsCircleFill className="mx-1" color="#dc3545" size={"1.25vh"} />;
        }
        if (this.state.CollectSolicitationOpen.status == 1) {
            return <BsCircleFill className="mx-1" color="#ffc107" size={"1.25vh"} />;
        }
        if (this.state.CollectSolicitationOpen.status == 2) {
            return <BsCircleFill className="mx-1" color="#2E8EB8" size={"1.25vh"} />;
        }
        if (this.state.CollectSolicitationOpen.status == 3) {
            return <BsCircleFill className="mx-1" color="#73B054" size={"1.25vh"} />;
        }
    }

    //Função que itera resultado do fetch do modal ao state da tela pertencente
    updateState = (item, data, item_id, id) => {
        //Usando prevState como função indica o estado anterior sem precisar passar o valor do state como props
        this.setState(prevState => ({
            //Forma dinâmica de iterar sobre o array de itens certo, exemplo: Trasnportadoras: [...prevState.Transportadoras, {NovoObjeto}], TransportadoraId: NovoId
            [item]: [
                ...prevState[item], { ...data }
            ],
            [item_id]: id
        }));
    }

    handleStatusChange = () => {
        const { DataRecebido, DataAgendamento, DataFinalizado, DataColeta } = this.state
        if (this.state.Situacao == 'Cancelada') {
            return <span>Cancelada<BsCircleFill className="mx-1" color="red" size={"1.25vh"} /></span>
        }
        if (DataFinalizado) {
            return <span>Finalizado<BsCircleFill className="mx-1" color="#73B054" size={"1.25vh"} /></span>
        }
        if (DataRecebido) {
            return <span>Recebido<BsCircleFill className="mx-1" color="#2E8EB8" size={"1.25vh"} /></span>
        }
        if (DataColeta) {
            return <span>Coletado<BsCircleFill className="mx-1" color="#2E8EB8" size={"1.25vh"} /></span>
        }
        if (DataAgendamento) {
            return <span>Agendado<BsCircleFill className="mx-1" color="#ffc107" size={"1.25vh"} /></span>
        }
        if (!DataFinalizado && !DataRecebido && !DataColeta && !DataAgendamento) {
            return <span>Rascunho<BsCircleFill className="mx-1" color="#dc3545" size={"1.25vh"} /></span>
        }
    }

    // Function to handle changes in date inputs
    handleDateChange = ({ target }) => {
        // Extracting the name and value of the input that triggered the event
        const { name, value } = target;

        // Switch statement to handle different date inputs
        switch (name) {
            case "DataAgendamento":

                console.log('mapitem', this.state.expedientData)

                // Setting the Situacao state to 'Agendado' and updating DataAgendamento with the new value
                if (value === "") {
                    this.setState({
                        DataAgendamento: value,
                        appointmentHour: "",
                        dailyHours: [],
                        workToday: false
                    });
                    return;
                }

                if (this.state.CollectSolicitationOpen && this.state.expedientData) {
                    let day = new Date(value).getUTCDay();
                    if (!this.state.numericWeekdays.includes(day)) {
                        this.setState({
                            DataAgendamento: value,
                            appointmentHour: "",
                            dailyHours: [],
                            workToday: false,
                        });
                    } else {
                        this.state.expedientData.map((item, id) => {
                            if (item.numeric_day == day) {
                                this.setState({
                                    DataAgendamento: value,
                                    appointmentHour: "",
                                    dailyHours: item.period,
                                    workToday: true,
                                });
                            }
                        });
                    }
                } else {
                    this.setState({ DataAgendamento: value });
                }

                break

            case "DataColeta":
                // Setting the Situacao state to 'Iniciado' and updating DataColeta with the new value
                if (value !== '' && this.state.mtr == null) {
                    this.setState({ DataColeta: value, DataRecebido: '', DataFinalizado: '' })
                }
                break

            case "DataRecebido":
                // Setting the Situacao state to 'Recebido' and updating DataRecebido with the new value
                if (value !== '') {
                    this.setState({ DataRecebido: value, DataFinalizado: '' });

                    this.setState({ processarReprocessarMTR: false });
                }
                break

            case "DataFinalizado":
                // Setting the Situacao state to 'Finalizado' and updating DataFinalizado with the new value
                if (value !== '') {
                    this.setState({ DataFinalizado: value })
                }
                break

            default:
                break
        }
    };

    handleMaterialChange = (value, item) => {
        let destinacao = { ...this.state.DestinacaoAtual };

        if (value == '') {
            destinacao.materiais = destinacao.materiais.map((item2, id2) => {
                if (item2.id === item.id) {
                    item2.peso = '';
                    item2.volume = '';
                    item2.peso_kg = '';
                    item2.volume_lt = '';
                }
                return item2;
            })
        } else {
            value = parseFloat(value)
            if (item.residuo_rejeito.unidade_principal.tipo == 'Peso') {
                let peso = value;
                let volume = value * parseFloat(item.residuo_rejeito.equivalencia)
                let peso_kg = value * parseFloat(item.residuo_rejeito.unidade_principal.equivalencia)
                let volume_lt = volume * parseFloat(item.residuo_rejeito.unidade_secundaria.equivalencia)

                destinacao.materiais = destinacao.materiais.map((item2, id2) => {
                    if (item2.id == item.id) {
                        item2.peso = peso;
                        item2.volume = volume;
                        item2.peso_kg = peso_kg;
                        item2.volume_lt = volume_lt;
                        item2.valor = peso_kg * item.valor_por_kg
                    }
                    return item2;
                })
            } else {
                let volume = value;
                let peso = value * parseFloat(item.residuo_rejeito.equivalencia)
                let peso_kg = peso * parseFloat(item.residuo_rejeito.unidade_secundaria.equivalencia)
                let volume_lt = value * parseFloat(item.residuo_rejeito.unidade_principal.equivalencia)

                destinacao.materiais = destinacao.materiais.map((item2, id2) => {
                    if (item2.id == item.id) {
                        item2.peso = peso;
                        item2.volume = volume;
                        item2.peso_kg = peso_kg;
                        item2.volume_lt = volume_lt;
                        item2.valor = volume_lt * item.valor_por_lt
                    }
                    return item2;
                })
            }
        }
        const PesoTotal = destinacao.materiais.reduce((total, item2) => {
            return total + Number(item2.peso_kg);
        }, 0);

        const VolumeTotal = destinacao.materiais.reduce((total, item2) => {
            return total + Number(item2.volume_lt);
        }, 0);

        const ReceitaTotal = destinacao.materiais.reduce((total, item2) => {
            if (item2.movimentacao === 'Receita') {
                const quantidade = item2.residuo_rejeito.tipo_medida_principal === 'Peso' ? Number(item2.peso_kg) : Number(item2.volume_lt)
                const equival = item2.residuo_rejeito.tipo_medida_principal === 'Peso' ? Number(item2?.valor_por_kg) : Number(item2?.valor_por_lt)
                return total + (equival * quantidade)
            }
            return total
        }, 0) || 0;

        const DespesaTotal = destinacao.materiais.reduce((total, item2) => {
            if (item2.movimentacao === 'Despesa') {
                const quantidade = item2.residuo_rejeito.tipo_medida_principal === 'Peso' ? Number(item2.peso_kg) : Number(item2.volume_lt)
                const equival = item2.residuo_rejeito.tipo_medida_principal === 'Peso' ? Number(item2?.valor_por_kg) : Number(item2?.valor_por_lt)
                return total + (equival * quantidade)
            }
            return total
        }, 0) || 0;
        const Saldo = ReceitaTotal - DespesaTotal

        this.setState({ destinacao, PesoTotal, VolumeTotal, ReceitaTotal, DespesaTotal, Saldo });
    }


    render() {
        const { token } = this.props;
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Destinação</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Finalize a destinação</p>
                            <br />

                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Destino: <span style={{ fontWeight: 'normal' }}>{this.state.DestinoNome}</span></label>
                                        </div>
                                        <hr />
                                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do estoque:</label>
                                        <div className="row">
                                            <strong className="align-middle">{this.state.statusUser ? `Alterado por ${this.state.statusUser.user}` : 'Não alterado'}</strong>
                                            <span className="align-middle">
                                                {this.state.statusUser ? <span>{this.state.statusUser.message}
                                                    <span className='small bold'> - {this.state.statusUser.created_at} às {this.state.statusUser.hour}</span>
                                                </span> : 'Descrição de alterações'}
                                            </span>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Estoque: <span style={{ fontWeight: 'normal' }}>{this.state.EstoqueNome}</span></label>
                                        </div>
                                        {this.state.MateriaisInseridos.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover td-center" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Unidade Principal</th>
                                                            <th>Unidade Secundária</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisInseridos.map((item) => (
                                                            <tr key={item.residuo_rejeito_id}>
                                                                <td>{item.nome}</td>
                                                                <td>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <NumericFormat disabled={this.state.Situacao == 'Cancelada'} className="form-control " id="peso"
                                                                            valueIsNumericString
                                                                            thousandsGroupStyle='thousand'
                                                                            thousandSeparator="."
                                                                            decimalScale={2}
                                                                            decimalSeparator=","
                                                                            placeholder={item.residuo_rejeito.tipo_medida_principal === 'Peso' ? "Informe o peso" : "Informe o volume"}
                                                                            type="text"
                                                                            style={{ marginRight: 10, maxWidth: 150 }}
                                                                            value={item.residuo_rejeito.tipo_medida_principal === 'Peso' ? item.peso : item.volume}
                                                                            onValueChange={(values, e) => { this.handleMaterialChange(values.value, item) }} />
                                                                        <span>{item.residuo_rejeito.unidade_principal.sigla}</span>
                                                                    </div>
                                                                </td>
                                                                <td>{(item.residuo_rejeito.tipo_medida_principal === 'Peso' ? item.volume : item.peso) + ' ' + item.residuo_rejeito.unidade_secundaria.sigla}</td>
                                                            </tr>
                                                        )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                                        {this.state.CollectSolicitationOpen &&
                                            <div className="form-group col-12 col-sm-6">
                                                <label className="col-sm-12 col-form-label" >Status da solicitação: <span style={{ fontWeight: 'normal' }}>{handleStatus(this.state.CollectSolicitationOpen.status)}{this.handleStatusCircle()}</span></label>
                                            </div>}
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.handleStatusChange()}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Agendado para: </label>
                                            <div className="col-sm-12">
                                                <input disabled={this.state.Situacao == 'Cancelada'} className="form-control" value={this.state.DataAgendamento} name='DataAgendamento' type="date" style={{ maxWidth: 168 }} onChange={this.handleDateChange} />


                                                {this.state.CollectSolicitationOpen && this.state.dailyHours.map((item, id) => (
                                                    <div key={id} className="col-12 col-sm-6 mt-3">
                                                        <div className="card text-center">
                                                            <div className="card-body">
                                                                <h5 className="card-title">{item.start_time} - {item.closing_time}</h5>
                                                                {this.state?.appointmentHour?.id != item.id && (
                                                                    <button className="btn btn-primary" onClick={() => this.setState({ appointmentHour: item })}>
                                                                        Selecionar
                                                                    </button>
                                                                )}
                                                                {this.state?.appointmentHour?.id == item.id && (
                                                                    <button className="btn btn-primary" disabled>
                                                                        Selecionado
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                {this.state.CollectSolicitationOpen ?
                                                    <div className="mt-2">
                                                        {this.state.workToday && this.state.appointmentHour && (
                                                            <Alert severity="info">
                                                                Entre {this.state.appointmentHour.start_time} e{" "}
                                                                {this.state.appointmentHour.closing_time}
                                                            </Alert>
                                                        )}
                                                        {/* {this.state.workToday && !this.state.DataAgendamento && (
                                                            <Alert severity="error">
                                                                Escolha um dia para o agendamento
                                                            </Alert>
                                                        )} */}
                                                        {!this.state.workToday && this.state.DataAgendamento && (
                                                            <Alert severity="info">
                                                                A empresa não funciona nesse dia da semana!
                                                            </Alert>
                                                        )}
                                                    </div> : <></>}
                                            </div>
                                            <small style={{ color: "gray" }}>Previsão de quando será feita a coleta dos materiais</small>
                                        </div>

                                        {(!this.state.CollectSolicitationOpen && this.state.CanGenerateCollect && this.props.blueenOpen) ?
                                            <div className="col-12">
                                                <button className='btn btn-primary btn-large mt-3' style={{ minWidth: 168, height: 38 }} onClick={() => this.solicitarColetaOpen()}
                                                    disabled={this.state.CollectLoading}
                                                >{this.state.CollectLoading ? <CircularProgress size={25} color='inherit' /> : 'Solicitar coleta'}</button>
                                            </div> : <></>}

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Data de coleta: </label>
                                            <div className="col-sm-12">
                                                <input disabled={this.state.mtr != null || this.state.Situacao == 'Cancelada'} className="form-control" value={this.state.DataColeta} name='DataColeta' type="date" style={{ maxWidth: 168 }} onChange={this.handleDateChange} />
                                            </div>
                                            <small style={{ color: "gray" }}>Data de retirada dos materiais</small>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Data de recebimento:</label>
                                            <div className="col-sm-12">
                                                <input disabled={this.state.Situacao == 'Cancelada'} className="form-control" value={this.state.DataRecebido} name='DataRecebido' type="date" style={{ maxWidth: 168 }} min={this.state.DataColeta} onChange={this.handleDateChange}
                                                // disabled={this.state.DataRecebido != '' ? true : false}
                                                // min={this.state.DataAtual}
                                                // max={this.state.DataAgendamento}
                                                />
                                            </div>
                                            <small style={{ color: "gray" }}>Data de quando os materiais chegaram no destino selecionado</small>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Data de finalização:</label>
                                            <div className="col-sm-12">
                                                <input disabled={this.state.Situacao == 'Cancelada'} className="form-control" id="data_finalizado" value={this.state.DataFinalizado} name='DataFinalizado' type="date" style={{ maxWidth: 168 }} min={this.state.DataRecebido} onChange={this.handleDateChange} />
                                            </div>
                                            <small style={{ color: "gray" }}>Data de quanto a solicitação for encerrada por completo (preencher caso clique em <strong>finalizar</strong>)</small>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label>{maskNumberBr(this.state.PesoTotal)} Kg</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label>{maskNumberBr(this.state.VolumeTotal)} L</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label> {maskMoneyV2(this.state.ReceitaTotal)}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label> {maskMoneyV2(this.state.DespesaTotal)}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label> {maskMoneyV2(this.state.Saldo)}</div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Transportadora</label>
                                            <div className="input-group">
                                                <select disabled={this.state.Situacao == 'Cancelada'} value={this.state.TransportadoraId} className="form-control"
                                                    onChange={(e) => {
                                                        if (this.state?.transport?.is_open && e.target.value === this.state.transport.transportadora.id) {
                                                            this.setState({
                                                                TransportadoraId: e.target.value,
                                                                VeiculoId: this.state.transport.veiculo.id,
                                                                MotoristaId: this.state.transport.motorista.id,
                                                                transportOpen: true
                                                            })
                                                        } else {
                                                            this.setState({ TransportadoraId: e.target.value, VeiculoId: '', MotoristaId: '', transportOpen: false })
                                                        }

                                                    }}>
                                                    {this.state?.transport?.is_open ?
                                                        <option value={this.state.transport.transportadora.id}>{this.state.transport.transportadora.nome_fantasia}</option> :
                                                        <option value={''}>Selecione uma opção</option>
                                                        // <></>
                                                    }

                                                    <option value={'proprio'}>Carro Próprio</option>
                                                    {this.state.Transportadoras.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}
                                                </select>
                                                <TransportadoraModal updateState={this.updateState} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Veículo</label>
                                            <div className="input-group">
                                                <select disabled={this.state.Situacao == 'Cancelada'} value={this.state.VeiculoId} className="form-control"
                                                    onChange={(e) => {
                                                        this.proprio = false;
                                                        for (let i = 0; i < this.state.Veiculos.length; i++) {
                                                            if (this.state.Veiculos[i].id === e.target.value) {
                                                                if (this.state.Veiculos[i].transportadora_id == null) {
                                                                    this.proprio = true;
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                        this.setState({ VeiculoId: e.target.value, MotoristaId: '' })
                                                    }}>
                                                    {this.state.transportOpen ?
                                                        <option value={this.state.transport.veiculo.id}>{this.state.transport.veiculo.modelo}</option> :
                                                        <option value={''}>Selecione uma opção</option>}


                                                    {this.state.Veiculos.filter((item, id) => {
                                                        if (item.transportadora_id == null && this.state.TransportadoraId === 'proprio') return true;
                                                        else if (item.transportadora_id === this.state.TransportadoraId) return true;
                                                        else return false;
                                                    }).map((item, id) => (
                                                        (<option key={id} value={item.id}>{item.modelo}</option>)
                                                    ))}

                                                </select>
                                                <VeiculoModal updateState={this.updateState} transportadoraId={this.state.TransportadoraId} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Motorista</label>
                                            <div className="input-group">
                                                <select disabled={this.state.Situacao == 'Cancelada'} value={this.state.MotoristaId} className="form-control"
                                                    onChange={(e) => { this.setState({ MotoristaId: e.target.value }) }}>
                                                    {this.state.transportOpen ?
                                                        <option value={this.state.transport.motorista.id}>{this.state.transport.motorista.nome}</option> :
                                                        <option value={''}>Selecione uma opção</option>}
                                                    |
                                                    {this.state.VeiculoId != '' && this.state.Motoristas.filter((item, id) => {
                                                        if (this.proprio === true) {
                                                            if (item.veiculo_id === this.state.VeiculoId) {
                                                                return true;
                                                            }
                                                            else {
                                                                return false;
                                                            }
                                                        }
                                                        else {
                                                            if (item.transportadora_id === this.state.TransportadoraId) return true;
                                                            else return false;
                                                        }
                                                        return item

                                                    }).map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                                </select>
                                                <MotoristaModal updateState={this.updateState} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Observações </label>
                                            <div className="col-sm-12">
                                                <textarea disabled={this.state.Situacao == 'Cancelada'} className="form-control" placeholder="Observações" value={this.state.Descricao} type="text" onChange={(e) => { this.setState({ Descricao: e.target.value }) }} />
                                            </div>
                                        </div>
                                        <hr />
                                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados fiscais</label>
                                        <div className="form-check form-switch">
                                            <input disabled={this.state.Situacao == 'Cancelada'} className="form-check-input" type="checkbox" role="switch" id="check-fiscal-data" checked={this.state.pendenciaFiscal} onChange={(e) => this.setState({ pendenciaFiscal: e.target.checked })} />
                                            <label className="form-check-label" htmlFor="check-fiscal-data">Não informar dados fiscais no momento</label>
                                        </div>
                                        {!this.state.pendenciaFiscal && <div id='fiscal'>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Chave da NF-e</label>
                                                <div className="col-sm-12">
                                                    <input disabled={this.state.Situacao == 'Cancelada'} className="form-control" placeholder="Chave de acesso da nota fiscal" value={this.state.ChaveNota} type="text" onChange={(e) => { this.setState({ ChaveNota: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="form-group  row">
                                                <div className=" col-sm-12">
                                                    <div className="row">
                                                        <div className="col-md-8 col-12">
                                                            <label className="col-sm-12 col-form-label">Número da NF-e </label>
                                                            <div className="col-sm-12">
                                                                <input disabled={this.state.Situacao == 'Cancelada'} className="form-control" placeholder="Número da nota fiscal" type="text" onChange={(e) => this.setState({ NumeroNota: e.target.value })} value={this.state.NumeroNota} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12">
                                                            <label className="col-sm-12 col-form-label">Série da NF-e </label>
                                                            <div className="col-sm-12">
                                                                <input disabled={this.state.Situacao == 'Cancelada'} className="form-control" placeholder="Série da nota fiscal" type="text" onChange={(e) => this.setState({ SerieNota: e.target.value })} value={this.state.SerieNota} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <FileInput
                                                isDisabled={this.state.Situacao == 'Cancelada'}
                                                label='Clique aqui para adicionar um anexo fiscal'
                                                fileLabel='Anexo da NF-e:'
                                                acceptedFiles='application/pdf,application/vnd.ms-excel'
                                                callback={(file) => this.setState({ AnexoNota: file })}
                                                filePath={this.state.AnexoNota}
                                                isOpen={this.state.CollectSolicitationOpen ? true : false}
                                            />
                                        </div>}

                                    </div>
                                </div>

                                <div className="mt-2">
                                    {this.state.mtr === null ? (
                                        <div>
                                            <b>MTR:</b>{' '}
                                            {this.state.Situacao != 'Cancelada' && <button className="btn btn-primary ml-2" disabled={!this.state.processarReprocessarMTR} onClick={() => this.processMTR(this.state.DestinacaoAtual.destinacao_interna_id)}>Processar/Reprocessar MTR</button>}
                                        </div>
                                    ) : this.state.mtr.lista_erros ? (
                                        <div className="mt-3 text-danger">
                                            <b>MTR:</b> {this.state.mtr.lista_erros}
                                            <div style={{ display: "block" }} className='mt-2'>
                                                {this.state.Situacao != 'Cancelada' && <button className="btn btn-primary" disabled={!this.state.processarReprocessarMTR} onClick={() => this.processMTR(this.state.DestinacaoAtual.destinacao_interna_id)}>Processar/Reprocessar MTR</button>}
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <span className="bold">MTR:</span>{' '}
                                            <button
                                                onClick={() => this.getMtr(this.state.mtr.codigo_manifesto_numero_nacional)}
                                                className="btn btn-success"
                                            >
                                                Baixar MTR
                                            </button>
                                            <button
                                                className="btn btn-danger"
                                                style={{ marginLeft: '2px' }}
                                                onClick={() => this.handleOpenModal(this.state.mtr.codigo_manifesto_numero_nacional)}
                                            >
                                                Cancelar MTR
                                            </button>
                                        </>
                                    )}
                                    <CancelMTRModal
                                        open={this.state.modalOpen}
                                        handleClose={this.handleCloseModal}
                                        mtrId={this.state.selectedMtrId}
                                        token={token}
                                    />
                                </div>

                                <div className="mt-2">
                                    {this.state.Cdf && this.state.Cdf.errors ? (
                                        <div className="mt-3 text-danger">
                                            <b>CDF:</b> {this.state.Cdf.error}
                                            {/* <button className="btn btn-primary ml-2" onClick={() => this.processCDF(this.state.Cdf.id)}>Processar/Reprocessar CDF</button> */}
                                        </div>
                                    ) : (
                                        this.state.Cdf ? (
                                            <>
                                                <span className='bold'>CDF:</span>{' '}
                                                <button className="btn btn-success" onClick={() => this.getCdf(this.state.Cdf.numeroCDF)}>Baixar CDF</button>
                                                <button style={{ marginLeft: '2px' }}
                                                    onClick={() => this.setState({
                                                        show_warning_desvincular_cdf: true
                                                    })}

                                                    className="btn btn-danger">Desvincular CDF</button>
                                            </>
                                        ) : (
                                            <>
                                                <span className='bold'>CDF:</span>{' '}
                                                <BuscarCDF handleCDFNumber={this.handleCDFNumber} findCDF={this.findCDF} searchCDF={this.state.searchCDF} />
                                            </>
                                        )
                                    )}
                                    <SweetAlert
                                        warning
                                        title={'Atenção'}
                                        onConfirm={() => this.vincularCdfaDestinacaoInterna()}
                                        onCancel={() => {
                                            this.setState({
                                                show_warning_cdf: false
                                            });
                                        }}
                                        show={this.state.show_warning_cdf}
                                        confirmBtnText="Sim"
                                        cancelBtnText="Cancelar"
                                        confirmBtnBsStyle="success"
                                        cancelBtnBsStyle="danger"
                                        showCancel={true}
                                    >
                                        Deseja vincular esse CDF a Destinacao Interna?
                                    </SweetAlert>
                                    <SweetAlert
                                        warning
                                        title={'Atenção'}
                                        onConfirm={() => this.desvincularCdfaDestinacaoInterna()}
                                        onCancel={() => {
                                            this.setState({
                                                show_warning_desvincular_cdf: false
                                            });
                                        }}
                                        show={this.state.show_warning_desvincular_cdf}
                                        confirmBtnText="Sim"
                                        cancelBtnText="Cancelar"
                                        confirmBtnBsStyle="success"
                                        cancelBtnBsStyle="danger"
                                        showCancel={true}
                                    >
                                        Deseja desvincular esse CDF a Destinacao Interna?
                                    </SweetAlert>
                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/destinacao_interna"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply />Voltar</span></Link>
                                    </div>
                                    {this.state.Situacao != 'Cancelada' && <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.finalizar()} className="btn btn-info btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Finalizar </button>

                                        <button type="button" onClick={() => this.handleUpdateMiddleware()} className="btn btn-info btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px', minWidth: 109 }} >{this.state.loading_save ? <div className="spinner-border text-primary" role="status"></div> : 'Salvar'}</button>
                                    </div>}

                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true &&
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                        <div className="spinner-border text-primary" role="status"></div>
                                    </div>
                                </div>}

                        </div>

                    </div>
                </div>
                {/* <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => this.props.history.push('/agendamentos')}
                    show={Boolean(this.state.CollectSolicitationOpen)}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="success"
                >
                    Essa destinação possui uma solicitação em pendente com o destino, aguarde a confirmação ou exclua o pedido dentro dos agendamentos!

                </SweetAlert> */}
            </div >
        );
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
        blueenOpen: state.AppReducer.permissoes?.blueen_open?.acesso,
    }
);

export default connect(mapsStateToProps, { logout })(ViewDestinacaoInterna);


